import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { FireactContext } from "@fireactjs/core";
import { SubscriptionContext } from "@fireactjs/saas";
import StreamIcon from '@mui/icons-material/Stream';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import { AnalyticsContext } from "../analytics/AnalyticsContext";

export const AnalyticsMenu = () => {
    const { config } = useContext(FireactContext);
    const pathnames = config.pathnames;
    const { subscription } = useContext(SubscriptionContext);
    const { analytics } = useContext(AnalyticsContext);
    const navigate = useNavigate();

    const { destinationId } = useParams();

    return (
        analytics !== null && destinationId && destinationId !== "" && 
            <List component="nav">
                <ListItem>
                <TextField
                    fullWidth
                    select
                    label="Analytics Profile"
                    value={destinationId}
                    onChange={(e) => navigate(pathnames.Dashboards.replace(":subscriptionId", subscription.id).replace(":destinationId", e.target.value))}
                >
                {analytics.map((instance, key) => (
                    <MenuItem key={key} value={instance.id}>{instance.name}</MenuItem>
                ))}
                </TextField>
                </ListItem>
                <Divider />
                <NavLink to={pathnames.Dashboards.replace(":subscriptionId", subscription.id).replace(":destinationId", destinationId)} style={{textDecoration:'none'}} key="dashboard">
                    <ListItemButton>
                        <ListItemIcon><SettingsEthernetIcon /></ListItemIcon>
                        <ListItemText primary={<Typography color="textPrimary">Dashboards</Typography>} />
                    </ListItemButton>
                </NavLink>
                <Divider key="settings-divider"/>
                <NavLink to={pathnames.Reports.replace(":subscriptionId", subscription.id).replace(":destinationId", destinationId)} style={{textDecoration:'none'}} key="settings">
                    <ListItemButton>
                        <ListItemIcon><StreamIcon /></ListItemIcon>
                        <ListItemText primary={<Typography color="textPrimary">Reports</Typography>} />
                    </ListItemButton>
                </NavLink>
            </List>
        
    )
}
