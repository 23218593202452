import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { Logo } from "./Logo";

export const Loader = ({size}) => {
	let cpSize = "35px";
	switch(size){
		case "small":
			cpSize = "30px";
			break;
		case "medium":
			cpSize = "35px";
			break;
		case "large":
			cpSize = "45px";
			break;
		default:
			cpSize = "35px";
			break;
	}
	return (
		<Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center"}}>
			<CircularProgress color="primary" size={cpSize} />
			<div style={{position: "absolute", paddingTop: "4px" }}>
				<Logo size={size} />
			</div>
		</Box>
	);
}