import { AuthContext, FireactContext, SetPageTitle } from "@fireactjs/core";
import { SubscriptionContext } from "@fireactjs/saas";
import { Container, Paper, Box, Typography, Alert, RadioGroup, Grid, FormControlLabel, Radio, Divider, Button, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../Loader";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

export const SetDestinationEventMapping = () => {
    const { destinationId, event } = useParams();
    const { subscription } = useContext(SubscriptionContext);
    const title = "Update Event";
    const navigate = useNavigate();
    const { config } = useContext(FireactContext);

    const { firebaseApp } = useContext(AuthContext);
    const db = getFirestore(firebaseApp);
    const [ enabled, setEnabled ] = useState("disabled");

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        setLoading(true);
        if(destinationId && destinationId !== ''){
            Promise.all([
                getDoc(doc(db, 'destinations', destinationId)),
                getDoc(doc(db, 'destinations/'+destinationId+'/event_mappings', event))
            ]).then(([destDoc, eventDoc]) => {
                // check destination and subscription are aligned
                if(destDoc.data().subscriptionId === subscription.id){
                    setEnabled(eventDoc.data().enabled?"enabled":"disabled");
                }else{
                    setError("No destination matches the requested destination ID.");
                }
                setLoading(false);    
            }).catch(error => {
                setError(error.message);
                setLoading(false);
            });
        }else{
            setError("Invalid event.");
            setLoading(false);
        }
    },[destinationId, db, subscription.id, event]);
    
    return (
        <Container maxWidth="xl">
            <SetPageTitle title={title} />
            <Paper>
                {loading?(
                    <Box p={2}>
                        <Loader />
                    </Box>
                ):(
                    <>
                        <Box p={2}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography component="h1" variant="h5"><strong>{title}</strong></Typography>
                                </Grid>
                            </Grid>
                            <Grid item>

                            </Grid>
                        </Box>
                        <Divider />
                        {error !== null &&
                            <Box p={2}>
                                <Alert severity="error">{error}</Alert>
                            </Box>
                        }
                        {success !== null &&
                            <Box p={2}>
                                <Alert severity="success">{success}</Alert>
                            </Box>
                        }
                        <Box p={2}>
                        <Grid container justifyContent={"center"} alignItems={"center"} direction={"row"}>
                            <Grid item xs>
                                <Typography variant="body1"><strong>Event:</strong> {event}</Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup name="status" value={enabled} onChange={(e) => setEnabled(e.target.value)}>
                                    <Stack direction={"row"} alignContent={"center"} gap={1}>
                                        <FormControlLabel value="enabled" control={<Radio />} label="Enabled"/>
                                        <FormControlLabel value="disabled" control={<Radio />} label="Disabled"/>
                                    </Stack>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                            
                        </Box>
                        <Box p={2}>
                            <Grid container>
                                <Grid item xs>
                                    <Button type="button" color="secondary" variant="outlined" disabled={processing} onClick={() => {
                                        if(destinationId && destinationId !== ''){
                                            navigate(config.pathnames.ViewDestination.replace(":subscriptionId", subscription.id).replace(":destinationId", destinationId));
                                        }else{
                                            navigate(config.pathnames.Connector.replace(":subscriptionId", subscription.id));
                                        }                                            
                                    }}>Back</Button>
                                </Grid>
                                <Grid item>
                                    <Button type="button" variant="contained" disabled={processing} onClick={() => {
                                        setProcessing(true);
                                        // validation
                                        setError(null);
                                        let hasError = false;
                                        if(hasError){
                                            setProcessing(false);
                                            const main = document.getElementsByTagName('main');
                                            if(main.length > 0){
                                                main[0].scrollTo(0,0);
                                            }
                                        }else{
                                            // saving event settings
                                            setDoc(doc(db, 'destinations/'+destinationId+'/event_mappings', event), {
                                                enabled: enabled==="enabled"?true:false
                                            }).then(() => {
                                                setSuccess('The event has been updated successfully');
                                                setProcessing(false);
                                                const main = document.getElementsByTagName('main');
                                                if(main.length > 0){
                                                    main[0].scrollTo(0,0);
                                                }    
                                            }).catch(error => {
                                                setError(error.message);
                                                setProcessing(false);
                                                const main = document.getElementsByTagName('main');
                                                if(main.length > 0){
                                                    main[0].scrollTo(0,0);
                                                }    
                                            });
                                        }
                                    }}>Save</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                )}
            </Paper>
        </Container>
    );
}