import { FireactContext, SetPageTitle } from "@fireactjs/core";
import { Box, Container, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { SubscriptionContext } from "@fireactjs/saas";
import { useNavigate } from "react-router-dom";

export const CreateDestination = () => {

    const title = "Create Destination";
    const { subscription } = useContext(SubscriptionContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();

    const destinations = [
        {
            type: "bigquery",
            title: "BigQuery",
            description: "Stream data to Google BigQuery for analytcis and single-customer-view",
            icon: <svg height="35" width="35" xmlns="http://www.w3.org/2000/svg" viewBox="-1.633235433328256 7.0326093303156565 131.26574682416876 114.63439066968435">
                    <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="64" x2="64" y1="7.034" y2="120.789">
                        <stop offset="0" stopColor="#0000008a"/><stop offset="1" stopColor="#0000008a"/>
                    </linearGradient><path d="M27.79 115.217L1.54 69.749a11.499 11.499 0 0 1 0-11.499l26.25-45.467a11.5 11.5 0 0 1 9.96-5.75h52.5a11.5 11.5 0 0 1 9.959 5.75l26.25 45.467a11.499 11.499 0 0 1 0 11.5l-26.25 45.467a11.5 11.5 0 0 1-9.959 5.749h-52.5a11.499 11.499 0 0 1-9.96-5.75z" fill="#949494"/>
                    <path clipPath="url(#b)" d="M119.229 86.48L80.625 47.874 64 43.425l-14.933 5.55L43.3 64l4.637 16.729 40.938 40.938 8.687-.386z" opacity=".07"/>
                    <g fill="#fff">
                        <path d="M64 40.804c-12.81 0-23.195 10.385-23.195 23.196 0 12.81 10.385 23.195 23.195 23.195S87.194 76.81 87.194 64c0-12.811-10.385-23.196-23.194-23.196m0 40.795c-9.72 0-17.6-7.88-17.6-17.6S54.28 46.4 64 46.4 81.6 54.28 81.6 64 73.72 81.6 64 81.6"/>
                        <path d="M52.99 63.104v7.21a12.794 12.794 0 0 0 4.38 4.475V63.104zM61.675 57.026v19.411c.745.137 1.507.22 2.29.22.714 0 1.41-.075 2.093-.189V57.026zM70.766 66.1v8.562a12.786 12.786 0 0 0 4.382-4.7v-3.861zM80.691 78.287l-2.403 2.405a1.088 1.088 0 0 0 0 1.537l9.115 9.112a1.088 1.088 0 0 0 1.537 0l2.403-2.402a1.092 1.092 0 0 0 0-1.536l-9.116-9.116a1.09 1.09 0 0 0-1.536 0"/>
                    </g>
                </svg>
        },
        {
            type: "pubsub",
            title: "PubSub",
            description: "Stream data to Google PubSub for real-time triggers and data processing",
            icon: <svg height="35" viewBox="-1.63323543 7.03260933 131.26574682 114.43939067" width="35" xmlns="http://www.w3.org/2000/svg">
                    <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="64" x2="64" y1="7.034" y2="120.789">
                        <stop offset="0" stopColor="#4387fd"/>
                        <stop offset="1" stopColor="#4683ea"/>
                    </linearGradient>
                    <path d="m27.79 115.217-26.25-45.468a11.499 11.499 0 0 1 0-11.499l26.25-45.467a11.5 11.5 0 0 1 9.96-5.75h52.5a11.5 11.5 0 0 1 9.959 5.75l26.25 45.467a11.499 11.499 0 0 1 0 11.5l-26.25 45.467a11.5 11.5 0 0 1 -9.959 5.749h-52.5a11.499 11.499 0 0 1 -9.96-5.75z" fill="#949494"/>
                    <path d="m121.054 79.58-31.836-31.834-5.858 1.214-14.603-14.602-4.673 8.739-2.534 10.109 4.289 4.29-8.238 1.682-11.296-11.296-7.67 7.373 14.123 14.125-14.97 11.9 40.193 40.192 19.581-.19z" opacity=".07"/>
                    <g fill="#fff">
                        <circle cx="85.519" cy="51.576" r="5.324"/>
                        <circle cx="42.48" cy="51.576" r="5.324"/>
                        <circle cx="64" cy="88.849" r="5.325"/>
                        <circle cx="64" cy="64" r="8.602"/>
                        <circle cx="42.48" cy="76.424" r="6.758"/>
                        <circle cx="85.519" cy="76.424" r="6.758"/>
                        <circle cx="64" cy="39.151" r="6.759"/>
                    </g>
                </svg>
        }
    ]

    return (
        <Container maxWidth="xl">
            <SetPageTitle title={title} />
            <Paper>
                <Box maxWidth="md" style={{margin: "auto"}}>
                    <Box p={2}>
                        <Typography component="h1" variant="h4" align="center">{title}</Typography>
                        <Typography component="p" align="center">Choose a destination type to start setting up a new destination to stream data to.</Typography>
                    </Box>
                    <Box p={2}>
                        <List>
                            {destinations.map((destination, index) => (
                                <ListItem key={index}>
                                    <ListItemButton sx={{borderStyle: 'solid', borderWidth: '1px', borderColor: "#dddddd", borderRadius: '10px'}} onClick={() => {
                                        navigate(config.pathnames.CreateDestinationType.replace(":subscriptionId", subscription.id).replace(":destType", destination.type))
                                    }}>
                                        <ListItemIcon>
                                            {destination.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={
                                                        <Typography variant="h6"><strong>{destination.title}</strong></Typography>
                                                    } secondary={<Typography>{destination.description}</Typography>} ></ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
}