import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";

export const DeleteDialog = ({text, confirmInput, open, handleClose, handleDelete}) => {
    const [confirmText, setConfirmText] = useState("");
    const [error, setError] = useState(null);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                Confirm Deletion 
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
                <TextField required fullWidth type="text" margin="normal" value={confirmText} onChange={(e) => setConfirmText(e.target.value)} error={error!==null?true:false} helperText={error!==null?error:""} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="contained" color="error" onClick={() => {
                    setError(null);
                    if(confirmInput !== confirmText){
                        setError("The input doesn't match with the confirmation text.");
                    }else{
                        handleDelete();
                    }
                }}>Delete</Button>
            </DialogActions>
        </Dialog>
    )
}