import { Box, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle } from "react";

export const SetDestinationPubSub = forwardRef((props, ref) => {

    const settings = props.settings;

    const [formData, setFormData] = useState({
        service_account_key: settings['service_account_key']?settings['service_account_key']:"",
        pubsub_project_id: settings['pubsub_project_id']?settings['pubsub_project_id']:"",
        event_as_pubsub_topic: settings['event_as_pubsub_topic']?settings['event_as_pubsub_topic']:"false",
        pubsub_topic: settings['pubsub_topic']?settings['pubsub_topic']:""
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        let tempErrors = {};

        // validate service_account_key has input
        if(formData.service_account_key.trim().length === 0){
            tempErrors.service_account_key = 'Please provide a GCP Service Account Key for connecting to your BigQuery.';
        }

        // validate pubsub_project_id has input
        if(formData.pubsub_project_id.trim().length === 0){
            tempErrors.pubsub_project_id = 'Please provide a Pub/Sub GCP Project ID for connecting to your Pub/Sub.';
        }

        if(formData.event_as_pubsub_topic==="false"){
            // validate pubsub_topic has input
            if(formData.pubsub_topic.trim().length === 0){
                tempErrors.pubsub_topic = 'Please provide a Pub/Sub Topic for connecting to your Pub/Sub.';
            }
        }else{
            // remove pubsub_topic error object
            delete tempErrors.pubsub_topic;
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleInputChange = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
        if (errors[name]) {
            // Optionally reset errors when the input is changed
            setErrors(prev => ({ ...prev, [name]: undefined }));
        }
    };

    useImperativeHandle(ref, () => ({
        validate,
        getFormData: () => {
            const settings = {
                service_account_key: formData['service_account_key'],
                pubsub_project_id: formData['pubsub_project_id'],
                event_as_pubsub_topic: formData['event_as_pubsub_topic'],
                pubsub_topic: formData['pubsub_topic']
            }
            return settings;
        }
    }));

    return (
        <>
             <Box p={2}>
                <TextField
                    required
                    fullWidth
                    error={errors.service_account_key?true:false}
                    helperText={errors.service_account_key?errors.service_account_key:"Copy and paste your GCP Service Account Key (JSON) here for authentication to Pub/Sub."} 
                    name="gcp_service_account"
                    label="GCP Service Account Key (JSON)"
                    type="text"
                    value={formData["service_account_key"]}
                    margin="normal"
                    multiline
                    rows={3}
                    onChange={e => handleInputChange("service_account_key", e.target.value.trim())}
                />
            </Box>
            <Box p={2}>
                <TextField
                    required
                    fullWidth
                    error={errors.pubsub_project_id?true:false}
                    helperText={errors.pubsub_project_id?errors.pubsub_project_id:"Specify the GCP project ID of your Pub/Sub instance."} 
                    name="pubsub_project"
                    label="Pub/Sub GCP Project ID"
                    type="text"
                    value={formData["pubsub_project_id"]}
                    margin="normal"
                    onChange={e => handleInputChange("pubsub_project_id", e.target.value.trim())}
                 />
            </Box>
            <Box p={2}>
                <FormControl fullWidth>
                    <FormLabel>Pub/Sub Topic</FormLabel>
                    <RadioGroup name="event_as_pubsub_topic" value={formData["event_as_pubsub_topic"]} onChange={e => handleInputChange("event_as_pubsub_topic", e.target.value.trim())} >
                        <FormControlLabel value="false" control={<Radio />} label="Specify a Pub/Sub topic for all events (recommended)" />
                        <FormControlLabel value="true" control={<Radio />} label="Use event names as Pub/Sub topics" />
                    </RadioGroup>
                </FormControl>
            </Box>
            {!(formData["event_as_pubsub_topic"] && formData["event_as_pubsub_topic"]==="true") &&
                <Box p={2}>
                    <TextField
                        required
                        fullWidth
                        error={errors.pubsub_topic?true:false}
                        helperText={errors.pubsub_topic?errors.pubsub_topic:"Specify the Pub/Sub topic name to stream all events to."} 
                        name="pubsub_topic"
                        label="Pub/Sub Topic"
                        type="text"
                        value={formData["pubsub_topic"]}
                        margin="normal"
                        onChange={e => handleInputChange("pubsub_topic", e.target.value.trim())}
                    />
                </Box>
            }
        </>
    );
});