import { FireactContext, SetPageTitle } from "@fireactjs/core";
import { Box, Container, List, ListItemButton, ListItem, ListItemIcon, ListItemText, Divider, Typography, Paper } from "@mui/material";
import React, { useContext } from "react";
import { Loader } from "../Loader";
import { Navigate, useNavigate } from "react-router-dom";
import { AnalyticsContext } from "./AnalyticsContext";
import { SubscriptionContext } from "@fireactjs/saas";
import AnalyticsIcon from '@mui/icons-material/Analytics';

export const Analytics = () => {

    const { subscription } = useContext(SubscriptionContext)
    const { config } = useContext(FireactContext);
    const { analytics } = useContext(AnalyticsContext);
    const navigate = useNavigate();

    return (
        <Container maxWidth="xl">
            <SetPageTitle title="Analytics" />
            <Paper>
                {analytics === null?(
                    <Box p={2}>
                        <Loader />
                    </Box>
                ):(
                    analytics.length === 0?(
                        <Navigate to={config.pathnames.CreateDestination.replace(":subscriptionId", subscription.id)} replace={true} />
                    ):(
                        analytics.length === 1?(
                            <Navigate to={config.pathnames.Dashboards.replace(":subscriptionId", subscription.id).replace(":destinationId", analytics[0].id)} replace={true} />
                        ):(
                            analytics.map((instance, key) => (
                                <React.Fragment key={key}>
                                    <List>
                                        <ListItemButton onClick={() => {
                                            navigate(config.pathnames.Dashboards.replace(":subscriptionId", subscription.id).replace(":destinationId", instance.id))
                                        }}>
                                            <ListItem alignItems="flex-start">
                                                <ListItemIcon>
                                                    <AnalyticsIcon fontSize="large" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={<Typography>{instance.name}</Typography>}
                                                />
                                            </ListItem>
                                        </ListItemButton>
                                    </List>
                                    {(key !== analytics.length - 1) &&
                                        <Divider />
                                    }
                                </React.Fragment>
                            ))
                        )
                    )
                )}
            </Paper>
        </Container>
    )
}