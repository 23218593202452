import { AuthContext, FireactContext, SetPageTitle } from "@fireactjs/core";
import { SubscriptionContext } from "@fireactjs/saas";
import { Box, Container, Button, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Paper, Radio, RadioGroup, TextField, Typography, Alert, Stack } from "@mui/material";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../Loader";
import destinationTypes from "../../../inc/destinations.json";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, doc, getDoc, setDoc, collection, addDoc } from "firebase/firestore";
import { SetDestinationBigQuery } from "./SetDestinationBigQuery";
import { SetDestinationPubSub } from "./SetDestinationPubSub";
import { clearCache } from "../../../inc/utility";

export const SetDestination = () => {
    const settingsComponentRef = useRef();

    const { destinationId } = useParams();
    const { destType } = useParams();
    const title = (destinationId && destinationId !== '')?"Update Destination":"Create Destination";
    const mode = (destinationId && destinationId !== '')?"update":"create";
    const { config } = useContext(FireactContext);
    const { subscription } = useContext(SubscriptionContext);
    const navigate = useNavigate();
    const { firebaseApp } = useContext(AuthContext);

    const [destinationName, setDestinationName] = useState("");
    const [destinationType, setDestinationType] = useState(destType);
    const [settings, setSettings] = useState({

    });
    const [enabled, setEnabled] = useState("enabled");

    const [error, setError] = useState(null); // top error message
    const [nameError, setNameError] = useState(null); // name field error message
    const [success, setSuccess] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [loading, setLoading] = useState(true);

    const functions = getFunctions(firebaseApp);
    const db = getFirestore(firebaseApp);

    useEffect(() => {
        setLoading(true);
        if(destinationId && destinationId !== ''){
            getDoc(doc(db, 'destinations', destinationId)).then(docSnap => {
                if(docSnap.exists && docSnap.data().subscriptionId === subscription.id){
                    // initialise the source variables
                    setDestinationName(docSnap.data().name);
                    setDestinationType(docSnap.data().type);
                    setEnabled(docSnap.data().enabled?"enabled":"disabled");
                    setSettings(docSnap.data().settings);
                }else{
                    if(!docSnap.new){
                        throw new Error("No destination matches the requested destination ID.");
                    }
                }
                setLoading(false);
            }).catch(err => {
                setError(err.message);
                setLoading(false);
            });
        }else{
            setLoading(false);
        }
    },[db, subscription.id, destinationId])
    

    const handleSubmit = () => {
        if (settingsComponentRef.current === undefined){
            // no settings component
            return {
                validated: true,
                settings: {}
            }
        }else{
            // Validate form data via the child component
            if (settingsComponentRef.current.validate()) {
                // Get form data from child if it's valid
                setSettings(settingsComponentRef.current.getFormData());
                return {
                    validated: true,
                    settings: settingsComponentRef.current.getFormData()
                }
                // Here you would typically send this data to a server or handle it as needed
            } else {
                return {
                    validated: false
                }
            }
        }
    };

    return (
        <Container maxWidth="xl">
            <SetPageTitle title={title} />
            <Paper>
                <Box maxWidth="md" style={{margin: "auto"}}>
                {loading?(
                    <Box p={2}>
                        <Loader />
                    </Box>
                ):(
                    success !== null?(
                        <>
                            <Box p={2}>
                                <Alert severity="success">{success}</Alert>
                            </Box>
                            <Box p={2} alignItems="center" justifyContent="center" display="flex">
                                {(destinationId && destinationId !== '')?(
                                    <Button type="button" color="primary" variant="contained" disabled={processing} onClick={() => {
                                        navigate(config.pathnames.ViewDestination.replace(":subscriptionId", subscription.id).replace(":destinationId", destinationId));
                                    }}>Back</Button>
                                ):(
                                    <Button type="button" color="primary" variant="contained" disabled={processing} onClick={() => {
                                        navigate(config.pathnames.Connector.replace(":subscriptionId", subscription.id));
                                    }}>Back</Button>
                                )}
                            </Box>
                        </>
                    ):(
                        <>
                            <Box p={2}>
                                <Typography component="h1" variant="h4" align="center">{title}</Typography>
                            </Box>
                            {error !== null &&
                                <Box p={2}>
                                    <Alert severity="error">{error}</Alert>
                                </Box>
                            }
                            <Box p={2}>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        name="destination_type"
                                        label="Destination Type"
                                        value={destinationType}
                                        disabled
                                    >
                                        {Object.keys(destinationTypes).map(key =>
                                            <MenuItem key={key} value={key}>{destinationTypes[key].label}</MenuItem>
                                        )}
                                    </TextField>
                                </FormControl>
                            </Box>
                            {destinationType !== "" &&
                                <>
                                    <Box p={2}>
                                        <TextField required fullWidth name="destination_name" label="Destination Name" type="text" margin="normal" value={destinationName} error={nameError?true:false} helperText={nameError?nameError:"Input a name for the destination to identify it."} onChange={(e) => {
                                            setNameError(null);
                                            setDestinationName(e.target.value);
                                        }} />
                                    </Box>
                                    {destinationType === "bigquery" &&
                                        <SetDestinationBigQuery settings={settings} ref={settingsComponentRef} mode={mode} />
                                    }
                                    {destinationType === "pubsub" &&
                                        <SetDestinationPubSub settings={settings} ref={settingsComponentRef} mode={mode} />
                                    }
                                    <Box p={2}>
                                        <FormControl fullWidth>
                                            <FormLabel>Status</FormLabel>
                                            <RadioGroup name="status" value={enabled} onChange={(e) => setEnabled(e.target.value)}>
                                                <Stack direction={"row"} alignContent={"center"} gap={1}>
                                                    <FormControlLabel value="enabled" control={<Radio />} label="Enabled"/>
                                                    <FormControlLabel value="disabled" control={<Radio />} label="Disabled"/>
                                                </Stack>
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </>
                            }
                            <Box p={2}>
                                <Grid container>
                                    <Grid item xs>
                                        <Button type="button" color="secondary" variant="outlined" disabled={processing} onClick={() => {
                                            if(destinationId && destinationId !== ''){
                                                navigate(config.pathnames.ViewDestination.replace(":subscriptionId", subscription.id).replace(":destinationId", destinationId));
                                            }else{
                                                navigate(config.pathnames.Connector.replace(":subscriptionId", subscription.id));
                                            }                                            
                                        }}>Back</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button type="button" variant="contained" disabled={processing} onClick={() => {
                                            setError(null);
                                            setSuccess(null);
                                            setProcessing(true);
                                            // validate and transform inputs
                                            let validationError = false;
                                            if(destinationName.trim().length === 0 || destinationName.trim().length > 50){
                                                validationError = true;
                                                setNameError("Please provide a destination name that is 1 to 50 characters in length.");
                                            }
                                            const validation = handleSubmit();
                                            if(validation.validated && !validationError){
                                                if(mode === "create"){
                                                    // create new destination
                                                    if(destinationType === "bigquery"){
                                                        const createBigQueryDestination = httpsCallable(functions, 'createBigQueryDestination');
                                                        createBigQueryDestination({
                                                            subscriptionId: subscription.id,
                                                            destination_name: destinationName,
                                                            settings: validation.settings,
                                                            enabled: enabled==="enabled"?true:false
                                                        }).then(res => {
                                                            setSuccess("The destination has been added successfully.");
                                                            setProcessing(false);                                                   
                                                        }).catch(err => {
                                                            setError(err.message);
                                                            setProcessing(false);
                                                        });
                                                    }else{
                                                        // create destination in firestore
                                                        addDoc(collection(db, 'destinations'), {
                                                            name: destinationName,
                                                            subscriptionId: subscription.id,
                                                            type: destinationType,
                                                            settings: validation.settings,
                                                            enabled: enabled==="enabled"?true:false
                                                        }).then(res => {
                                                            return clearCache(subscription.server, "subscription", subscription.id);
                                                        }).then(res => {
                                                            setSuccess("The destination has been added successfully.");
                                                            setProcessing(false);
                                                        }).catch(err => {
                                                            setError(err.message);
                                                            setProcessing(false);
                                                        });
                                                    }
                                                }else{
                                                    // update existing destination
                                                    if(destinationType === "bigquery"){
                                                        // update bigquery settings
                                                        const updateBigQueryDestination = httpsCallable(functions, 'updateBigQueryDestination');
                                                        updateBigQueryDestination({
                                                            destinationId: destinationId,
                                                            name: destinationName,
                                                            enabled: enabled==="enabled"?true:false,
                                                            settings: validation.settings
                                                        }).then(res => {
                                                            setSuccess("The destination has been updated successfully.");
                                                            setProcessing(false);                                                   
                                                        }).catch(err => {
                                                            setError(err.message);
                                                            setProcessing(false);
                                                        });
                                                    }else{
                                                        // update destination in firestore
                                                        setDoc(doc(db, 'destinations', destinationId), {
                                                            name: destinationName,
                                                            settings: validation.settings,
                                                            enabled: enabled==="enabled"?true:false
                                                        }, {merge: true}).then(res => {
                                                            return clearCache(subscription.server, "destination", destinationId);
                                                        }).then(res => {
                                                            setSuccess("The destination has been updated successfully.");
                                                            setProcessing(false);
                                                        }).catch(err => {
                                                            setError(err.message);
                                                            setProcessing(false);
                                                        });
                                                    }
                                                }
                                            }else{
                                                setError("Please correct your inputs and try again.");
                                                setProcessing(false);
                                                return;
                                            }
                                        }} >Save</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )
                )}
                </Box>
            </Paper>
        </Container>
    )
}