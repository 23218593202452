import { Box, TextField } from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle } from "react";

export const SetSourceApi = forwardRef((props, ref) => {

    const settings = props.settings;

    const [formData, setFormData] = useState({
        token: settings['token']?settings['token']:""
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        let tempErrors = {};
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleInputChange = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
        if (errors[name]) {
            // Optionally reset errors when the input is changed
            setErrors(prev => ({ ...prev, [name]: undefined }));
        }
    };

    useImperativeHandle(ref, () => ({
        validate,
        getFormData: () => {
            const settings = {
                token: formData['token']
            }
            return settings;
        }
    }));

    return (
        <>
            <Box p={2}>
                <TextField fullWidth name="token" label="API Token" type="text" value={formData['token']} margin="normal" onChange={e => handleInputChange("token", e.target.value.trim())} />
            </Box>
        </>
    );
});