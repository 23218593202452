import { AuthContext, FireactContext } from "@fireactjs/core";
import { SubscriptionContext } from "@fireactjs/saas";
import { Box, Container, Paper, Alert, Grid, Typography, Stack, Button, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Chart from "react-google-charts";
import { doc, getDoc, setDoc } from 'firebase/firestore';

export const UsageDashboard = ({loader}) => {

    const { subscription, setSubscription } = useContext(SubscriptionContext);
    const [usageData, setUsageData] = useState(null);
    const [ error, setError ] = useState(null);
    const { config } = useContext(FireactContext);
    const { firestoreInstance } = useContext(AuthContext);
    const [refreshCount, setRefreshCount] = useState(0);
    const [ plan, setPlan ] = useState(null);
    const [ budget, setBudget ] = useState(null);
    const [ disableSaveButton, setDisableSaveButton ] = useState(false);

    useEffect(() => {
        config.saas.plans.forEach(plan => {
            if(plan.id === subscription.planId){
                setPlan(plan);
            }
        });
        if(subscription.budgetEvents){
            setBudget(subscription.budgetEvents);
        }
    }, [subscription, config.saas.plans]);

    useEffect(() => {

        function convertTimestamps(input) {
            const sortedKeys = Object.keys(input).sort((a, b) => a - b);
            const output = [];
            output.push(['Period', 'Events'])
            sortedKeys.forEach((timestamp, index, array) => {
              const date = new Date(timestamp * 1000);
              const dateString = date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
              let label = `${dateString} to present`;
              if (index < array.length - 1) {
                const endDate = new Date(array[index + 1] * 1000).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
                label = `${dateString} to ${endDate}`;
              }
              output.push([label, input[timestamp]]);
            });
            return output;
        }

        setError(null);
        setUsageData(null);
        const docRef = doc(firestoreInstance, "subscriptions", subscription.id);
        getDoc(docRef).then(docSnap => {
            if(docSnap.exists()){
                if(subscription.usage){
                    if(docSnap.data().usage){
                        const data = convertTimestamps(docSnap.data().usage);
                        setUsageData(data);    
                    }else{
                        setUsageData([]);
                    }
                }else{
                    setUsageData([]);
                }
            }else{
                // no subscription
                setError("No "+config.saas.subscription.singular+" matches the ID");
                setUsageData([]);
            }
        }).catch(error => {
            setUsageData([]);
            setError(error.message);
        })

    },[subscription, config.saas.subscription.singular, firestoreInstance, refreshCount]);

    return (
        <>
            {usageData === null?(
                <>{loader}</>
            ):(
                error !== null?(
                    <Box mt={10}>
                        <Container maxWidth="sm">
                            <Box component="span" m={5} textAlign="center">
                                <Alert severity="error" >{error}</Alert>
                            </Box>
                        </Container>
                    </Box>
                ):(
                <Container maxWidth="lx">
                    <Paper>
                        <Box p={2}>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography component="h1" variant="h4">Usage Dashboard</Typography>
                                    <Typography>The usage data is updated hourly. Please click the Refresh Data button to load the latest data.</Typography>
                                </Grid>
                                <Grid item textAlign="right">
                                    <Stack direction="row-reverse" spacing={1} mt={2}>
                                        <Button color="info" variant="outlined" size="small" onClick={() => setRefreshCount(prevState => (prevState+1))}>Refresh Data</Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                        {usageData.length > 0?(
                            <Box p={2}>
                                <Chart chartType="ColumnChart" width={"100%"} height={"500px"} data={usageData} />
                            </Box>
                        ):(
                            <Box p={2}>
                                <Stack spacing={2}>
                                    <Typography>There is no usage. To start sending data, please setup your data sources.</Typography>
                                    <Typography>If you have sent data, please allow one hour for the usage data to be updated.</Typography>
                                </Stack>
                            </Box>
                        )}
                        {plan !== null && plan.free?(
                            <Box p={2}>
                                <Stack spacing={2}>
                                    <Typography>Monthly event call cap is {plan.cap.toLocaleString()}. The system will not process events beyond the cap when the usage exceeded the cap, until the next subscription period.</Typography>
                                    <Typography>Alerts will be sent out to the subscription owner via emails when event calls reach 50%, 75%, 90% and 100% of the cap.</Typography>
                                </Stack>
                            </Box>
                        ):(
                            <Box p={2}>
                                <Stack spacing={2}>
                                    <Typography component="h2" variant="h5">Monthly Usage Alert Setting</Typography>
                                    <div>
                                        <TextField size="small" label={"Montly Event Budget"} type="number" placeholder="1000000" value={budget?budget:""} onChange={(e) => setBudget(e.target.value===""?null:parseInt(e.target.value))}/> <Button variant="contained" disabled={disableSaveButton} onClick={() => {
                                            setDisableSaveButton(true);
                                            // store the budget to firestore
                                            setDoc(doc(firestoreInstance, 'subscriptions', subscription.id), {
                                               budgetEvents: budget
                                            }, {merge: true}).then(res => {
                                                 // update subscription context
                                                 subscription.budgetEvents = budget;
                                                 setSubscription(subscription);
                                            }).then(() => {
                                                setDisableSaveButton(false);
                                            }).catch(err => {
                                                setDisableSaveButton(false);
                                            })
                                        }} >Save</Button>
                                    </div>
                                    <Typography>Monthly event call budget is {subscription.budgetEvents?subscription.budgetEvents.toLocaleString():"not set"}. The system will continue to process events after the usage exceeded the budget and you will be billed based on the actual usage.</Typography>
                                    <Typography>Alerts will be sent out to the subscription owner via emails when event calls reach 50%, 75%, 90% and 100% of the budget. Please set your budget below to receive alerts.</Typography>
                                </Stack>
                            </Box>
                        )}
                    </Paper>
                </Container>
                )
            )}
        </>
    )
}