import { AuthContext, FireactContext, SetPageTitle } from "@fireactjs/core";
import { Alert, Box, Button, Container, Divider, FormControlLabel, Grid, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loader } from "../Loader";
import EditIcon from '@mui/icons-material/Edit';
import { SubscriptionContext } from "@fireactjs/saas";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DeleteDialog } from "../dialogs/DeleteDialog";
import { getFirestore, getDoc, doc, getDocs, collection, setDoc, deleteDoc } from "firebase/firestore";
import { clearCache } from "../../inc/utility";

export const ViewDestination = () => {
    const { destinationId } = useParams();
    const title = "View Destination";
    const { firebaseApp } = useContext(AuthContext);
    const db = getFirestore(firebaseApp);
    const { config } = useContext(FireactContext);
    const { subscription } = useContext(SubscriptionContext);

    const [destination, setDestination] = useState(null);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const [deleteDialgOpen, setDeleteDialogOpen] = useState(false);

    useEffect(() => {
        setLoading(true);
        if(destinationId && destinationId !== ''){
            Promise.all([
                getDoc(doc(db, 'destinations', destinationId)),
                getDocs(collection(db, 'destinations/'+destinationId+'/event_mappings'))
            ]).then(([destDoc, eventMappingCollection]) => {
                // check destination and subscription are aligned
                if(destDoc.exists && destDoc.data().subscriptionId === subscription.id){
                    const destination = {
                        name: destDoc.data().name,
                        type: destDoc.data().type,
                        enabled: destDoc.data().enabled,
                        events: []
                    }
                    const events = [];
                    eventMappingCollection.forEach(event => {
                        events.push({
                            name: event.id,
                            enabled: event.data().enabled
                        });
                    })
                    destination.events = events;
                    setDestination(destination);
                }else{
                    setError("No destination matches the requested destination ID.");
                }
                setLoading(false);    
            }).catch(error => {
                setError(error.message);
                setLoading(false);
            });
        }else{
            setError("Invalid destination.");
            setLoading(false);
        }
    },[destinationId, db, subscription.id]);

    return (
        <Container maxWidth="xl">
            <SetPageTitle title={title} />
            <Paper>
                {loading?(
                    <Box p={2}>
                        <Loader />
                    </Box>
                ):(
                    <>

                        {error !== null &&
                            <Box p={2}>
                                <Alert severity="error">{error}</Alert>
                            </Box>
                        }
                        <Box p={2}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography component="h2" variant="h5">{destination.name}
                                        <FormControlLabel style={{paddingLeft: "15px"}} control={<Switch defaultChecked={destination.enabled} onChange={(e) => {
                                            setDoc(doc(db, 'destinations', destinationId), {enabled: e.target.checked}, {merge: true}).then(() => {
                                                // clear cache
                                                setDestination(dest => ({...dest, enabled: e.target.checked}));
                                                clearCache(subscription.server, 'destination', destinationId);
                                            }).catch(error => {
                                                console.log(error);
                                                setError(error.message);
                                                setLoading(false);
                                            })
                                        }}  />} label={destination.enabled?"Enabled":"Disabled"} />
                                    </Typography>
                                    <Typography variant="caption"><strong>Destination Type: {destination.type}</strong></Typography>
                                </Grid>
                                <Grid item>
                                    <Stack direction={"row"} alignContent={"center"} gap={1}>
                                        <Button type="button" color="secondary" variant="outlined" onClick={() => navigate(config.pathnames.Connector.replace(":subscriptionId", subscription.id))}>Connector</Button>
                                        <Button type="button" color="error" variant="outlined" onClick={() => setDeleteDialogOpen(true)}>
                                            <DeleteForeverIcon fontSize="small" style={{paddingRight: "5px"}} />
                                            Delete
                                        </Button>
                                        <Button variant="outlined" onClick={() => navigate(config.pathnames.UpdateDestination.replace(":subscriptionId", subscription.id).replace(":destinationId", destinationId))}>
                                            <EditIcon fontSize="small" style={{paddingRight: "5px"}}/>
                                            Update
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider />
                        <Box p={2}>
                            <Typography component="h2" variant="h6"><strong>Events</strong></Typography>
                        </Box>
                        <Box p={2}>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} size="small">
                                    <TableBody>
                                        {destination.events.map((event, key) => (
                                            <TableRow hover key={key}>
                                                <TableCell>
                                                    <Stack direction={"row"} alignContent={"center"} gap={1}>
                                                        {event.enabled?(
                                                            <CheckCircleIcon color="success" />
                                                        ):(
                                                            <CancelIcon color="error" />
                                                        )}
                                                        {event.name}
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {destination.type === "bigquery" && 
                                                        <Button size="small" variant="outlined" onClick={() => navigate(config.pathnames.UpdateBigQueryDestinationEventMapping.replace(":subscriptionId", subscription.id).replace(":destinationId", destinationId).replace(":event", event.name))}>
                                                            <EditIcon fontSize="small" style={{paddingRight: "5px"}}/>
                                                            Update
                                                        </Button>
                                                    }
                                                    {destination.type === "pubsub" && 
                                                        <Button size="small" variant="outlined" onClick={() => navigate(config.pathnames.UpdateDestinationEventMapping.replace(":subscriptionId", subscription.id).replace(":destinationId", destinationId).replace(":event", event.name))}>
                                                            <EditIcon fontSize="small" style={{paddingRight: "5px"}}/>
                                                            Update
                                                        </Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <DeleteDialog
                            text={`Are you sure you want to delete destination ${destination.nme}? This action cannot be undone. To confirm, type "${destination.name}" in the input field and click the "Delete" button.`}
                            open={deleteDialgOpen}
                            confirmInput={destination.name}
                            handleClose={() => setDeleteDialogOpen(false)}
                            handleDelete={() => {
                                setError(null);
                                setDeleteDialogOpen(false);
                                deleteDoc(doc(db, 'destinations', destinationId)).then(() => {
                                    return clearCache(subscription.server, 'subscription', subscription.id);
                                }).then(() => {
                                    navigate(config.pathnames.Connector.replace(":subscriptionId", subscription.id));
                                }).catch(error => {
                                    setError(error.message);
                                })
                            }}
                        />
                    </>
                )}
            </Paper>
        </Container>
    )
}