// a react component to display the JS SDK instructions
import { Divider } from "@mui/material";
import React from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism';

export const ViewSourceApi = ({sourceId, server}) => {

    return (
        <div>
            <Divider />
            <h3>Description</h3>
            <p>The HTTP API endpoint gathers user data from any data source via JSON format.</p>
            <h3>Implementation</h3>
            <p>Once you have created an HTTPS API endpoint, you can post the data to the endpoint <b>{`https://${server}.datacord.io/events/${sourceId}`}</b> in the following JSON format. Each request can collect multiple events as an array, reducing the number of network requests.</p>
            <SyntaxHighlighter language={"json"} style={materialOceanic} wrapLongLines={true} customStyle={{fontSize: '0.8rem'}}>
{`[{
	event_id: "event_unique_id_1",
	event: "event_type_1",
    user_id: "user_123",
	device_id: "device_unique_id",
	session_id: "session_123",
	view_id: "pageview_xyz",
	timestamp: 1683363983423,
	data: {
		prop1: "value1",
		prop2: "value2"
	}
},
{
	event_id: "event_unique_id_2",
	event: "event_type_2",
    user_id: "user_123",
	device_id: "device_unique_id",
	session_id: "session_123",
	view_id: "pageview_xyz",
	timestamp: 1683363983423,
	data: {
		prop1: "value1",
		prop2: "value2"
	}
}]`}
            </SyntaxHighlighter>
        </div>
    )
}