import { List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { FireactContext, AuthContext } from "@fireactjs/core";
import { checkPermission, SubscriptionContext } from "@fireactjs/saas";
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

export const ConnectorMenu = () => {
    const { config } = useContext(FireactContext);
    const pathnames = config.pathnames;
    const { subscription } = useContext(SubscriptionContext);
    const defaultPermissions = [];
    const adminPermissions = [];

    for(let permission in config.saas.permissions){
        if(config.saas.permissions[permission].default){
            defaultPermissions.push(permission);
        }
        if(config.saas.permissions[permission].admin){
            adminPermissions.push(permission);
        }
    }

    return (
        <List component="nav">
            <AuthContext.Consumer>
                {context => (
                    <>
                        {checkPermission(subscription, context.authUser.user.uid, ["connector"]) && 
                            <>
                                <NavLink to={pathnames.Connector.replace(":subscriptionId", subscription.id)} style={{textDecoration:'none'}} key="dashboard">
                                    <ListItemButton>
                                        <ListItemIcon><SettingsEthernetIcon /></ListItemIcon>
                                        <ListItemText primary={<Typography color="textPrimary">Connector</Typography>} />
                                    </ListItemButton>
                                </NavLink>
                            </>
                        }
                    </>
                )}
            </AuthContext.Consumer>
        </List>
    )
}
