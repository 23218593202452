import React from "react";

export const Logo = ({size, color}) => {
	let px = 32;
	switch(size){
		case "small":
			px = 18;
			break;
		case "medium":
			px = 24;
			break;
		case "large":
			px = 36;
			break;
		default:
			px = 24;
			break;
	}
	return (
		<img src="/logo64.png" alt="logo" width={px} height={px} />
	);
}