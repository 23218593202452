import React, {useContext} from "react";
import { Button, Box, Stack } from "@mui/material";
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SettingsIcon from '@mui/icons-material/Settings';
import { FireactContext } from "@fireactjs/core";
import { useNavigate } from "react-router-dom";
import { SubscriptionContext } from "@fireactjs/saas";

export const TopMenu = () => {

    const { config } = useContext(FireactContext);
    const pathnames = config.pathnames;
    const navigate = useNavigate();
    const { subscription } = useContext(SubscriptionContext);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Stack spacing={5} direction="row">
                <Button variant="text" startIcon={<SettingsEthernetIcon />} style={{color: "#ffffff"}} onClick={() => navigate(pathnames.Connector.replace(":subscriptionId", subscription.id))}>
                    Connector
                </Button>
                <Button variant="text" startIcon={<SettingsIcon />} style={{color: "#ffffff"}} onClick={() => navigate(pathnames.UsageDashboard.replace(":subscriptionId", subscription.id))}>
                    Admin
                </Button>
            </Stack>
        </Box>
    )
}