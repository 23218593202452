// a react component to display the JS SDK instructions
import { Divider } from "@mui/material";
import React from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism';

export const ViewSourceiOS = ({sourceId, server}) => {

    return (
        <div>
            <Divider />
            <h3>Description</h3>
            <p>The iOS SDK is designed to gather user data from your iOS native apps.</p>
            <h3>Installation</h3>
            <p>Download the SDK and import it to your app project. In your App settings, go to the <b>General</b> tab. Under <b>Frameworks, Libraries, and Embedded Content</b>, locate the SDK and set it to "Embed & Sign" to complete the installation.</p>
            <h3>Tracking and Identifying Users</h3>
            <p><b>Initialising the SDK</b></p>
            <p>Once the SDK is installed, initialise the SDK with the following code</p>
            <SyntaxHighlighter language={"swift"} style={materialOceanic} wrapLongLines={true} customStyle={{fontSize: '0.8rem'}}>
{`import DatacordSDK

init() {
    DatacordSDK.setup(server: "${server}", sourceId: "${sourceId}")
}`}
            </SyntaxHighlighter>
            <p>The setup function will automatically collect the "app_start" event and set the "device_id" and "session_id", which will be associated with all subsequent events.</p>
            <p><b>Track Views</b></p>
            <p>To track views, follow the example below to trigger the tracking in the <b>.onAppear</b> of the view. Replace the "view_name" with a user friendly name of the view.</p>
            <SyntaxHighlighter language={"swift"} style={materialOceanic} wrapLongLines={true} customStyle={{fontSize: '0.8rem'}}>
{`.onAppear {
	DatacordSDK.shared?.trackView(viewName: "view_name")
}`}
            </SyntaxHighlighter>
            <p><b>Track User Identity and Custom Data</b></p>
            <p>You can send custom events and custom data with the "track" function provided by the SDK. Below is an example to send a event "user_identified" with the user ID. Please note that this is a special event that will attach the user ID to the device ID so that all anonymous events with the device ID are associated with the user.</p>
            <SyntaxHighlighter language={"swift"} style={materialOceanic} wrapLongLines={true} customStyle={{fontSize: '0.8rem'}}>
{`let data: [String: Any] = [
	"user_id": "user_id_123"
]
DatacordSDK.shared?.track(event: "user_identified", data: data)`}
            </SyntaxHighlighter>
            <p>You can modify the event name and data to send custom events and custom data you need to track.</p>
        </div>
    )
}