import { AuthContext, FireactContext, SetPageTitle } from "@fireactjs/core";
import { SubscriptionContext } from "@fireactjs/saas";
import { Box, Button, Container, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader";
import ApiIcon from '@mui/icons-material/Api';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import sourceTypes from "../../inc/sources.json";
import destinationTypes from "../../inc/destinations.json";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import JavascriptIcon from '@mui/icons-material/Javascript';
import AndroidIcon from '@mui/icons-material/Android';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

import { getFirestore, query, collection, getDocs, where } from 'firebase/firestore';


export const Connector = () => {

    const { firebaseApp } = useContext(AuthContext);
    const { subscription } = useContext(SubscriptionContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [sources, setSources] = useState(null);
    const [destinations, setDestinations] = useState(null);

    useEffect(() => {
        const queries = [];
        const db = getFirestore(firebaseApp);
        queries.push(getDocs(query(collection(db, "sources"), where('subscriptionId', '==', subscription.id))));
        queries.push(getDocs(query(collection(db, "destinations"), where('subscriptionId', '==', subscription.id))));
        Promise.all(queries).then(([sourceSnapshot, destinationSnapshot]) => {
            const sources = [];
            sourceSnapshot.forEach(sourceDoc => {
                sources.push({
                    id: sourceDoc.id,
                    name: sourceDoc.data().name,
                    type: sourceDoc.data().type,
                    enabled: sourceDoc.data().enabled
                });
            });
            setSources(sources);
            const destinations = [];
            destinationSnapshot.forEach(destinationDoc => {
                destinations.push({
                    id: destinationDoc.id,
                    name: destinationDoc.data().name,
                    type: destinationDoc.data().type,
                    enabled: destinationDoc.data().enabled
                });
            });
            setDestinations(destinations);
        }).catch(err => {
            console.log(err);
        })
    },[firebaseApp, subscription.id]);

    return (
        <Container maxWidth="xl">
            <SetPageTitle title="Connector" />
            <Paper>
                <Box p={2}>
                    <Grid container spacing={5} direction="row" justify="center" alignItems="stretch">
                        <Grid item xs={5}>
                            <Grid container p={2}>
                                <Grid item xs>
                                    <Typography variant="h5"><strong>Sources</strong></Typography>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={() => {
                                        navigate(config.pathnames.CreateSource.replace(":subscriptionId", subscription.id))
                                    }}>
                                        Add Source
                                    </Button>
                                </Grid>
                            </Grid>
                            <Divider />
                            {sources === null?(
                                <div style={{margin: '20px'}}>
                                    <Loader />
                                </div>
                            ):(<>{sources.map((source, i) => 
                                <React.Fragment key={i}>
                                    <List>
                                        <ListItem>
                                            <ListItemButton sx={{borderStyle: 'solid', borderWidth: '1px', borderColor: "#dddddd", borderRadius: '10px'}} onClick={() => {
                                                navigate(config.pathnames.ViewSource.replace(":subscriptionId", subscription.id).replace(":sourceId", source.id))
                                            }}>
                                                <ListItemIcon>
                                                    {(source.type === 'web-js') &&
                                                        <JavascriptIcon fontSize="large" />
                                                    }
                                                    {(source.type === 'api') &&
                                                        <ApiIcon fontSize="large" />
                                                    }
                                                    {(source.type === 'android') &&
                                                        <AndroidIcon fontSize="large" />
                                                    }
                                                    {(source.type === 'ios') &&
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="35" width="35" viewBox="-17.64309 -14.589975 152.90678 87.53985"><path d="M.5468 57.4219h9.7266v-41.406H.5468zm4.8438-46.836c3.0469 0 5.4297-2.3438 5.4297-5.2734C10.8203 2.3437 8.4375 0 5.3906 0 2.3828 0 0 2.3438 0 5.3125c0 2.9297 2.3828 5.2734 5.3906 5.2734zM42.6576.1169c-16.445 0-26.758 11.211-26.758 29.141s10.312 29.102 26.758 29.102c16.406 0 26.719-11.172 26.719-29.102S59.0646.1169 42.6576.1169zm0 8.5938c10.039 0 16.445 7.9688 16.445 20.547 0 12.539-6.4062 20.508-16.445 20.508-10.078 0-16.445-7.9688-16.445-20.508 0-12.578 6.3672-20.547 16.445-20.547zm30.822 32.852c.4297 10.391 8.9453 16.797 21.914 16.797 13.633 0 22.227-6.7188 22.227-17.422 0-8.3984-4.8438-13.125-16.289-15.742l-6.4844-1.4844c-6.9141-1.6406-9.7656-3.8281-9.7656-7.5781 0-4.6875 4.2969-7.8125 10.664-7.8125 6.4453 0 10.859 3.1641 11.328 8.4375h9.6094c-.2344-9.9219-8.4375-16.641-20.859-16.641-12.266 0-20.977 6.7578-20.977 16.758 0 8.0469 4.9219 13.047 15.312 15.43l7.3047 1.7188c7.1094 1.6797 10 4.0234 10 8.0859 0 4.6875-4.7266 8.0469-11.523 8.0469-6.875 0-12.07-3.3984-12.695-8.5938h-9.7656z" fill="#949494"/></svg>
                                                    }
                                                </ListItemIcon>
                                                <ListItemText primary={
                                                    <Typography variant="h6"><strong>{source.name}</strong></Typography>
                                                } secondary={sourceTypes[source.type].label} />
                                                {source.enabled?(
                                                    <Tooltip title="Enabled" placement="top" arrow>
                                                        <CheckCircleIcon color="success" fontSize="small" />
                                                    </Tooltip>
                                                ):(
                                                    <Tooltip title="Disabled" placement="top" arrow>
                                                        <CancelIcon color="error" fontSize="small" />
                                                    </Tooltip>
                                                )}
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </React.Fragment>
                            )}
                            </>)}
                        </Grid>
                        <Grid item xs={2} p={5}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{marginTop: "100px"}}
                            >
                                <SettingsEthernetIcon sx={{transform: "scale(4)"}} color="disabled" />
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container p={2}>
                                <Grid item xs>
                                    <Typography variant="h5"><strong>Destinations</strong></Typography>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={() => {
                                        navigate(config.pathnames.CreateDestination.replace(":subscriptionId", subscription.id))
                                    }}>
                                        Add Destination
                                    </Button>
                                </Grid>
                            </Grid>
                            <Divider />
                            {destinations === null?(
                                <div style={{margin: '20px'}}>
                                    <Loader />
                                </div>
                            ):(<>{
                                destinations.map((destination, i) => 
                                    <React.Fragment key={i}>
                                        <List key={destination.id}>
                                            <ListItem>
                                                <ListItemButton sx={{borderStyle: 'solid', borderWidth: '1px', borderColor: "#dddddd", borderRadius: '10px'}} onClick={() => {
                                                    navigate(config.pathnames.ViewDestination.replace(":subscriptionId", subscription.id).replace(":destinationId", destination.id))
                                                }}>
                                                    <ListItemIcon>
                                                        {(destination.type === 'bigquery') &&
                                                            <svg height="35" width="35" xmlns="http://www.w3.org/2000/svg" viewBox="-1.633235433328256 7.0326093303156565 131.26574682416876 114.63439066968435">
                                                                <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="64" x2="64" y1="7.034" y2="120.789">
                                                                    <stop offset="0" stopColor="#0000008a"/><stop offset="1" stopColor="#0000008a"/>
                                                                </linearGradient><path d="M27.79 115.217L1.54 69.749a11.499 11.499 0 0 1 0-11.499l26.25-45.467a11.5 11.5 0 0 1 9.96-5.75h52.5a11.5 11.5 0 0 1 9.959 5.75l26.25 45.467a11.499 11.499 0 0 1 0 11.5l-26.25 45.467a11.5 11.5 0 0 1-9.959 5.749h-52.5a11.499 11.499 0 0 1-9.96-5.75z" fill="#949494"/>
                                                                <path clipPath="url(#b)" d="M119.229 86.48L80.625 47.874 64 43.425l-14.933 5.55L43.3 64l4.637 16.729 40.938 40.938 8.687-.386z" opacity=".07"/>
                                                                <g fill="#fff">
                                                                    <path d="M64 40.804c-12.81 0-23.195 10.385-23.195 23.196 0 12.81 10.385 23.195 23.195 23.195S87.194 76.81 87.194 64c0-12.811-10.385-23.196-23.194-23.196m0 40.795c-9.72 0-17.6-7.88-17.6-17.6S54.28 46.4 64 46.4 81.6 54.28 81.6 64 73.72 81.6 64 81.6"/>
                                                                    <path d="M52.99 63.104v7.21a12.794 12.794 0 0 0 4.38 4.475V63.104zM61.675 57.026v19.411c.745.137 1.507.22 2.29.22.714 0 1.41-.075 2.093-.189V57.026zM70.766 66.1v8.562a12.786 12.786 0 0 0 4.382-4.7v-3.861zM80.691 78.287l-2.403 2.405a1.088 1.088 0 0 0 0 1.537l9.115 9.112a1.088 1.088 0 0 0 1.537 0l2.403-2.402a1.092 1.092 0 0 0 0-1.536l-9.116-9.116a1.09 1.09 0 0 0-1.536 0"/>
                                                                </g>
                                                            </svg>
                                                        }
                                                        {(destination.type === 'pubsub') &&
                                                            <svg height="35" viewBox="-1.63323543 7.03260933 131.26574682 114.43939067" width="35" xmlns="http://www.w3.org/2000/svg">
                                                                <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="64" x2="64" y1="7.034" y2="120.789">
                                                                    <stop offset="0" stopColor="#4387fd"/>
                                                                    <stop offset="1" stopColor="#4683ea"/>
                                                                </linearGradient>
                                                                <path d="m27.79 115.217-26.25-45.468a11.499 11.499 0 0 1 0-11.499l26.25-45.467a11.5 11.5 0 0 1 9.96-5.75h52.5a11.5 11.5 0 0 1 9.959 5.75l26.25 45.467a11.499 11.499 0 0 1 0 11.5l-26.25 45.467a11.5 11.5 0 0 1 -9.959 5.749h-52.5a11.499 11.499 0 0 1 -9.96-5.75z" fill="#949494"/>
                                                                <path d="m121.054 79.58-31.836-31.834-5.858 1.214-14.603-14.602-4.673 8.739-2.534 10.109 4.289 4.29-8.238 1.682-11.296-11.296-7.67 7.373 14.123 14.125-14.97 11.9 40.193 40.192 19.581-.19z" opacity=".07"/>
                                                                <g fill="#fff">
                                                                    <circle cx="85.519" cy="51.576" r="5.324"/>
                                                                    <circle cx="42.48" cy="51.576" r="5.324"/>
                                                                    <circle cx="64" cy="88.849" r="5.325"/>
                                                                    <circle cx="64" cy="64" r="8.602"/>
                                                                    <circle cx="42.48" cy="76.424" r="6.758"/>
                                                                    <circle cx="85.519" cy="76.424" r="6.758"/>
                                                                    <circle cx="64" cy="39.151" r="6.759"/>
                                                                </g>
                                                            </svg>
                                                        }                                     
                                                    </ListItemIcon>
                                                    <ListItemText primary={
                                                        <Typography variant="h6"><strong>{destination.name}</strong></Typography>
                                                    } secondary={destinationTypes[destination.type].label} />
                                                   {destination.enabled?(
                                                        <Tooltip title="Enabled" placement="top" arrow>
                                                            <CheckCircleIcon color="success" fontSize="small" />
                                                        </Tooltip>
                                                    ):(
                                                        <Tooltip title="Disabled" placement="top" arrow>
                                                            <CancelIcon color="error" fontSize="small" />
                                                        </Tooltip>
                                                    )}
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </React.Fragment>
                                )
                            }
                            </>
                            )}                            
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Container>

    )
}