import { FireactContext } from "@fireactjs/core";
import { SubscriptionContext } from "@fireactjs/saas";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AccountHome = () => {
    const navigate = useNavigate();
    const { subscription } = useContext(SubscriptionContext);
    const { config } = useContext(FireactContext);
    useEffect(() => {
        navigate(config.pathnames.Connector.replace(":subscriptionId", subscription.id));
    },[config.pathnames.Connector, subscription.id, navigate])
    

    return (<></>)
}