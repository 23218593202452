import { FireactContext } from "@fireactjs/core";
import { Grid, Stack, TextField, MenuItem, FormControl } from "@mui/material";
import React, { useContext } from "react";

export const LocationSelector = ({selectedLocation, changeLocation}) => {

    const { config } = useContext(FireactContext);
    const locationOptions = config.saas.locations.options;

    return (
        <Stack spacing={3}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item md={8} textAlign={"center"}>
                    <b>Pick a data center location for processing data:</b>
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item md={2} textAlign={"center"}>
                    <FormControl fullWidth>
                        <TextField
                            select
                            label="Data Centre Location"
                            value={selectedLocation}
                            onChange={(event) => changeLocation(event.target.value)}
                        >
                            {locationOptions.map(loc =>
                                <MenuItem key={loc.id} value={loc.id}>{loc.name}</MenuItem>
                            )}
                        </TextField>
                    </FormControl>
                </Grid>
            </Grid>
        </Stack>
    )
}