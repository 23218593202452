import firebaseConfig from "./firebaseConfig.json";
import { pathnames, AppTemplate, AuthProvider, AuthRoutes, MainMenu, PublicTemplate, ResetPassword, SignIn, SignUp, UserMenu, UserProfile, UserUpdateEmail, UserUpdateName, UserUpdatePassword, UserDelete, FireactProvider, ActionPages } from '@fireactjs/core';
import { BrowserRouter, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import authMethods from "./authMethods.json";
import { pathnames as subPathnames, PermissionRouter, Settings, ListUsers, SubscriptionProvider, ListInvoices, ManagePaymentMethods, CancelSubscription, UpdateBillingDetails } from '@fireactjs/saas';
import { CreateSubscription } from './components/CreateSubscription';
import SaaSConfig from './config.json';
import { TopMenu } from "./components/menus/TopMenu";
import { UsageDashboard } from "./components/admin/UsageDashboard";
import { AdminMenu } from "./components/menus/AdminMenu";
import { ConnectorMenu } from "./components/menus/ConnectorMenu";
import { SetSource } from "./components/connector/SetSource"
import { Connector } from "./components/connector/Connector";
import { Loader } from "./components/Loader";
import { Logo } from "./components/Logo";
import { SetDestination } from "./components/connector/SetDesitnation";
import { Analytics } from "./components/analytics/Analytics";
import { AnalyticsProvider } from "./components/analytics/AnalyticsContext";
import { AnalyticsMenu } from "./components/menus/AnalyticsMenu";
import { ViewDestination } from "./components/connector/ViewDestination";
import { SetDestinationEventMapping } from "./components/connector/SetDestinationEventMapping";
import { ViewSource } from "./components/connector/ViewSource";
import { AccountHome } from "./components/AccountHome";
import { ChangePlan } from "./components/ChangePlan";
import { SetBigQueryDestinationEventMapping } from "./components/connector/SetBigQueryDestinationEventMappoing";
import { ListSubscriptions } from "./components/ListSubscriptions";
import { CreateSource } from "./components/connector/CreateSource";
import { CreateDestination } from "./components/connector/CreateDestination";

const Brand = "FIREACT";

function App() {

	// merge pathnames
	for(var key in subPathnames){
		pathnames[key] = subPathnames[key];
	}

	const config = {
		firebaseConfig: firebaseConfig,
		brand: "Datacord",
		pathnames: pathnames,
		authProviders: authMethods,
		saas: SaaSConfig
	}
	

	// set admin pathnames
	pathnames["Settings"] = "/sub/:subscriptionId/admin/settings";
	pathnames["ListUsers"] = "/sub/:subscriptionId/admin/users";
	pathnames["ChangePlan"] = "/sub/:subscriptionId/admin//billing/plan";
	pathnames["ListInvoices"] = "/sub/:subscriptionId/admin/billing";
	pathnames["ManagePaymentMethods"] = "/sub/:subscriptionId/admin/billing/payment-method"
	pathnames["CancelSubscription"] = "/sub/:subscriptionId/admin/billing/cancel";
	pathnames["UsageDashboard"] = "/sub/:subscriptionId/admin/";

	pathnames["AccountHome"] = "/sub/:subscriptionId/";

	// set connector pathnames
	pathnames["Connector"] = "/sub/:subscriptionId/connector/";
	pathnames["CreateSource"] = "/sub/:subscriptionId/connector/create-source";
	pathnames["CreateSourceType"] = "/sub/:subscriptionId/connector/create-source/type/:sourceType";
	pathnames["UpdateSource"] = "/sub/:subscriptionId/connector/source/:sourceId/update";
	pathnames["ViewSource"] = "/sub/:subscriptionId/connector/source/:sourceId/view";
	pathnames["CreateDestination"] = "/sub/:subscriptionId/connector/create-destination";
	pathnames["CreateDestinationType"] = "/sub/:subscriptionId/connector/create-destination/type/:destType";
	pathnames["ViewDestination"] = "/sub/:subscriptionId/connector/destination/:destinationId/view";
	pathnames["UpdateDestination"] = "/sub/:subscriptionId/connector/destination/:destinationId/update";
	pathnames["UpdateDestinationEventMapping"] = "/sub/:subscriptionId/connector/destination/:destinationId/default/event/:event";
	pathnames["UpdateBigQueryDestinationEventMapping"] = "/sub/:subscriptionId/connector/destination/:destinationId/bigquery/event/:event";
	pathnames["Events"] = "/sub/:subscriptionId/connector/events";

	// set analytics pathnames
	pathnames["Analytics"] = "/sub/:subscriptionId/analytics/";
	pathnames["Dashboards"] = "/sub/:subscriptionId/analytics/:destinationId/dashboards";
	pathnames["Reports"] = "/sub/:subscriptionId/analytics/:destinationId/reports";


	return (
		<FireactProvider config={config}>
			<AuthProvider firebaseConfig={firebaseConfig} brand={Brand}>
				<BrowserRouter>
					<Routes>
						<Route element={<AuthRoutes loader={<Loader size="large" />} />} >
							<Route element={<AppTemplate logo={<Logo size="large" />} toolBarMenu={<UserMenu />} drawerMenu={<MainMenu />} />}>
								<Route exact path={pathnames.ListSubscriptions} element={<ListSubscriptions loader={<Loader size="large" />} />} />
								<Route exact path={pathnames.CreateSubscription} element={<CreateSubscription />} />
								<Route exact path={pathnames.UserProfile} element={<UserProfile />} />
								<Route exact path={pathnames.UserUpdateEmail} element={<UserUpdateEmail />} />
								<Route exact path={pathnames.UserUpdateName} element={<UserUpdateName />} />
								<Route exact path={pathnames.UserUpdatePassword} element={<UserUpdatePassword />} />
								<Route exact path={pathnames.UserDelete} element={<UserDelete />} />
							</Route>
							
							<Route path={pathnames.Subscription} element={<SubscriptionProvider loader={<Loader size="large" />} />} >
								<Route path={pathnames.AccountHome} element={<AccountHome />} />
								<Route element={<PermissionRouter permissions={["analytics"]} />} >
									<Route path={pathnames.Analytics} element={<AnalyticsProvider />} >
										<Route element={<AppTemplate logo={<Logo size="large" />} toolbarChildren={<TopMenu />} toolBarMenu={<UserMenu />} drawerMenu={<AnalyticsMenu />} />}>
											<Route exact path={pathnames.Analytics} element={<Analytics />} />
											<Route exact path={pathnames.Dashboards} element={<></>} />
											<Route exact path={pathnames.Reports} element={<></>} />
										</Route>
									</Route>
								</Route>
								<Route element={<PermissionRouter permissions={["connector"]} />} >
									<Route element={<AppTemplate logo={<Logo size="large" />} toolbarChildren={<TopMenu />} toolBarMenu={<UserMenu />} drawerMenu={<ConnectorMenu />} />}>
										<Route exact path={pathnames.Connector} element={<Connector />} />
										<Route exact path={pathnames.CreateSourceType} element={<SetSource />} />
										<Route exact path={pathnames.CreateSource} element={<CreateSource />} />
										<Route exact path={pathnames.UpdateSource} element={<SetSource />} />
										<Route exact path={pathnames.ViewSource} element={<ViewSource />} />
										<Route exact path={pathnames.CreateDestinationType} element={<SetDestination />} />
										<Route exact path={pathnames.CreateDestination} element={<CreateDestination />} />
										<Route exact path={pathnames.ViewDestination} element={<ViewDestination />} />
										<Route exact path={pathnames.UpdateDestination} element={<SetDestination />} />
										<Route exact path={pathnames.UpdateDestinationEventMapping} element={<SetDestinationEventMapping />} />
										<Route exact path={pathnames.UpdateBigQueryDestinationEventMapping} element={<SetBigQueryDestinationEventMapping />} />
									</Route>
								</Route>
								<Route element={<PermissionRouter permissions={["admin"]} />} >
									<Route element={<AppTemplate logo={<Logo size="large" />} toolbarChildren={<TopMenu />} toolBarMenu={<UserMenu />} drawerMenu={<AdminMenu />} />}>
										<Route exact path={pathnames.UsageDashboard} element={<UsageDashboard loader={<Loader size="large" />} />} />
										<Route exact path={pathnames.Settings} element={<Settings loader={<Loader size="large" />} />} />
										<Route exact path={pathnames.ListUsers} element={<ListUsers loader={<Loader size="large" />} />} />
										<Route exact path={pathnames.ListInvoices} element={<ListInvoices loader={<Loader size="large" />} />} />
										<Route exact path={pathnames.ManagePaymentMethods} element={<ManagePaymentMethods loader={<Loader size="large" />} />} />
										<Route exact path={pathnames.UpdateBillingDetails} element={<UpdateBillingDetails loader={<Loader size="large" />} />} />
										<Route exact path={pathnames.ChangePlan} element={<ChangePlan />} />
										<Route exact path={pathnames.CancelSubscription} element={<CancelSubscription />} />
									</Route>
								</Route>
							</Route>
						</Route>
						<Route element={<PublicTemplate />}>
							<Route path={pathnames.SignIn} element={
								<SignIn
									logo={<Logo size="large" />}
								/>
							} />
							<Route path={pathnames.SignUp} element={
								<SignUp
									logo={<Logo size="large" />}
								/>
							} />
							<Route path={pathnames.ResetPassword} element={
								<ResetPassword
									logo={<Logo size="large" />}
								/>
							} />
							<Route path={pathnames.ActionPages} element={
								<ActionPages
									logo={<Logo size="large" />}
								/>
							} />
						</Route>
					</Routes>
				</BrowserRouter>
			</AuthProvider>
		</FireactProvider>
	)
}

export default App;