import { FireactContext, SetPageTitle } from "@fireactjs/core";
import { Box, Container, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import JavascriptIcon from '@mui/icons-material/Javascript';
import AndroidIcon from '@mui/icons-material/Android';
import ApiIcon from '@mui/icons-material/Api';
import AppleIcon from '@mui/icons-material/Apple';
import { SubscriptionContext } from "@fireactjs/saas";
import { useNavigate } from "react-router-dom";

export const CreateSource = () => {

    const title = "Create Source";
    const { subscription } = useContext(SubscriptionContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();

    const sources = [
        {
            type: "web-js",
            title: "Web JavaScript SDK",
            description: "Collect data from your website",
            icon: <JavascriptIcon fontSize="large" />
        },
        {
            type: "ios",
            title: "iOS SDK",
            description: "Collect data from your iOS Native Apps",
            icon: <AppleIcon fontSize="large" />
        },
        {
            type: "android",
            title: "Android SDK",
            description: "Collect data from your Android Native Apps",
            icon: <AndroidIcon fontSize="large" />
        },
        {
            type: "api",
            title: "API",
            description: "Collect data from any other data sources",
            icon: <ApiIcon fontSize="large" />
        }
    ]

    return (
        <Container maxWidth="xl">
            <SetPageTitle title={title} />
            <Paper>
                <Box maxWidth="md" style={{margin: "auto"}}>
                    <Box p={2}>
                        <Typography component="h1" variant="h4" align="center">{title}</Typography>
                        <Typography component="p" align="center">Choose a source type to start setting up a new source to collect data.</Typography>
                    </Box>
                    <Box p={2}>
                        <List>
                            {sources.map((source, index) => (
                                <ListItem key={index}>
                                    <ListItemButton sx={{borderStyle: 'solid', borderWidth: '1px', borderColor: "#dddddd", borderRadius: '10px'}} onClick={() => {
                                        navigate(config.pathnames.CreateSourceType.replace(":subscriptionId", subscription.id).replace(":sourceType", source.type))
                                    }}>
                                        <ListItemIcon>
                                            {source.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={
                                                        <Typography variant="h6"><strong>{source.title}</strong></Typography>
                                                    } secondary={<Typography>{source.description}</Typography>} ></ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
}