// a react component to display the JS SDK instructions
import { Button, Divider, Icon, Link, Stack } from "@mui/material";
import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CodeIcon from '@mui/icons-material/Code';
import SvgIcon from "@mui/material/SvgIcon";

export const ViewSourceJs = ({sourceId, server}) => {
    const [method, setMethod] = useState("on-page");

    return (
        <div>
            <Divider />
            <h3>Description</h3>
            <p>The Web JavaScript SDK is designed to gather user data from web pages. It automatically captures page views, clicks, and data layer events among others. The data collection can be configured via the source settings interface.</p>
            <h3>Installation</h3>
            <Stack direction="row" spacing={2}>
                <Button variant={method==="on-page"?"contained":"outlined"} startIcon={<CodeIcon />} onClick={() => setMethod("on-page")}>On-page JavaScript</Button>
                <Button variant={method==="gtm"?"contained":"outlined"} startIcon={
                    <Icon>
                        <SvgIcon viewBox="0 0 220 220">
                            <g>
                                <polygon fill="#8AB4F8" points="111.31,176.79 80.76,147 146.37,80 178,111 	"/>
                                <path fill="#4285F4" d="M111.44,45.08L81,14L14.44,79.93c-8.58,8.58-8.58,22.49,0,31.08L80,177l31-29L61.05,95.47L111.44,45.08z"/>
                                <path fill="#8AB4F8" d="M177.56,80.44l-66-66c-8.59-8.59-22.52-8.59-31.11,0c-8.59,8.59-8.59,22.52,0,31.11l66,66c8.59,8.59,22.52,8.59,31.11,0C186.15,102.96,186.15,89.03,177.56,80.44z"/>
                                <circle fill="#246FDB" cx="95.5" cy="162.5" r="21.5"/>
                            </g>
                        </SvgIcon>
                    </Icon>
                } onClick={() => setMethod("gtm")}>Google Tag Manager</Button>
            </Stack>
            {method === "on-page" && 
                <p>Paste this code as high in the <b>&lt;head&gt;</b> of the page as possible:</p>
            }
            {method === "gtm" &&
                <div>
                    <p>Follow the instructions to add the code to Google Tag Manager:</p>
                    <ul>
                        <li>Create a <b>Custom HTML</b> tag named "Datacord Tag"</li>
                        <li>Copy the code below and paste it to the Custom HTML tag.</li>
                        <li>In <b>Advanced Settings</b>, select <b>Once per page</b> for <b>Tag firing options</b>.</li>
                        <li>Set trigger condition to <b>All Pages</b>.</li>
                        <li>Publish the tag to live websites.</li>
                    </ul>
                </div>
            }
            <SyntaxHighlighter language={"javascript"} style={materialOceanic} wrapLongLines={true} customStyle={{fontSize: '0.8rem'}}>
           
{`<script>
!function(){ var async = function(c){ var d = document, s = d.createElement('script'), h = d.getElementsByTagName('head')[0]; s.src = 'https://cdn.datacord.io/datacord.js'; if(c){s.addEventListener('load', function(e){c(null,e);}, false);} h.appendChild(s); }; async(function(){ inst = new _Analytics("${sourceId}","${server}"); }); }();
</script>`}
            
            </SyntaxHighlighter>
            <h3>Tracking and Identifying Users</h3>
            <p>The SDK automatically tracks the automatic events based on the configurations set in the web interface. It also tracks custom events in data layer objects, including eCommerce events.</p>
            <p>You can define a data layer name such as "dataLayer" in the configurations to collect events in the data layer object. The data layer is a JSON array that you can push events into and each pushed event will trigger data collection in the SDK.</p>
            <p>For example, the code below collects the user identity with the data layer named "dataLayer".</p>
            <SyntaxHighlighter language={"javascript"} style={materialOceanic} wrapLongLines={true} customStyle={{fontSize: '0.8rem'}}>
{`dataLayer.push({
    'event': 'user_identified',
    'user_id': 'cNBMvhGM3AV7TA4nIbe1eUiLVVT2'
});`}
            </SyntaxHighlighter>
            <p>For more details, please see <Link href="https://docs.datacord.io/docs/sources/web_javascript_sdk/" target="_blank" variant="body2" >the documentation</Link>.</p>
        </div>
    )
}