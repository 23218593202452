import React, { useContext, useEffect, useState } from "react";
import { Outlet } from 'react-router-dom';
import { AuthContext } from "@fireactjs/core";
import { Alert, Box, Container } from "@mui/material";
import { SubscriptionContext } from "@fireactjs/saas";
import { Loader } from "../Loader";
import { getFirestore, getDocs, collection, query, where } from 'firebase/firestore';

export const AnalyticsContext = React.createContext();

export const AnalyticsProvider = () => {
    const { subscription } = useContext(SubscriptionContext);

    const { firebaseApp } = useContext(AuthContext);
    const db = getFirestore(firebaseApp);

    const [ error, setError ] = useState(null);

    const [analytics, setAanalytics] = useState(null);

    useEffect(() => {
        setError(null);
        getDocs(query(collection(db, "destinations"), where('subscriptionId', '==', subscription.id))).then(res => {
            const destinations = [];
            res.forEach(destinationDoc => {
                if(destinationDoc.data().enabled && destinationDoc.data().type==="analytics"){
                    // only read the enabled analytics destinations
                    destinations.push({
                        id: destinationDoc.id,
                        name: destinationDoc.data().name,
                        type: destinationDoc.data().type
                    });    
                }
            });
            setAanalytics(destinations);
        }).catch(err => {
            setError(err.message);
        })
    }, [subscription, db]);

    return (
        <>
            {error !== null?(
                <Box mt={10}>
                    <Container maxWidth="sm">
                        <Box component="span" m={5} textAlign="center">
                            <Alert severity="error" >{error}</Alert>
                        </Box>
                    </Container>
                </Box>
            ):(
                <>
                    {subscription !== null?(
                        <AnalyticsContext.Provider value={{analytics, setAanalytics}}>
                            <Outlet />
                        </AnalyticsContext.Provider>
                    ):(
                        <Box mt={10}>
                            <Container maxWidth="sm">
                                <Box component="span" m={5} textAlign="center">
                                    <Loader />
                                </Box>
                            </Container>
                        </Box>
                    )}
                </>
            )}
            
        </>
    )

}