import { FireactContext } from "@fireactjs/core";
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import StarIcon from '@mui/icons-material/Star';

export const PricingPlans = ({selectedCurrency, selectedLocation, selectedPlanId, disabled, selectPlan, paymentMethod}) => {

    const { config } = useContext(FireactContext);
    const plans = config.saas.plans;

    return (
        <Grid container spacing={5} justifyContent="flex-end" alignItems="center" direction="row">
            {plans.map((plan, i) => (
                plan.legacy === false && (plan.currency === selectedCurrency || plan.currency === "") &&
                <Grid
                    item
                    key={i}
                    xs={12}
                    md={4}
                >
                    <Card>
                        <CardHeader 
                            title={plan.title}
                            style={{minHeight: "100px"}}
                            subheader={plan.popular ? "Most Popular": ""}
                            titleTypographyProps={{ align: 'center' }}
                            action={plan.popular ? <StarIcon color="success" /> : null}
                            subheaderTypographyProps={{
                            align: 'center',
                            }}
                            sx={{
                            backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[700],
                            }}
                        />
                        <CardContent>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'baseline',
                                mb: 2
                                }}
                            >
                                <Typography component="h2" variant="h5" color="text.primary">
                                    {plan.price}
                                </Typography>
                            </Box>
                            <Box sx={{
                                minHeight: '200px'
                            }}
                            >
                                <ul style={{listStyleType: 'none', paddingLeft: '0px'}}>
                                {plan.description.map((line) => (
                                    <Typography
                                    component="li"
                                    variant="subtitle1"
                                    align="center"
                                    key={line}
                                    >
                                        {line}
                                    </Typography>
                                ))}
                                </ul>
                            </Box>
                        </CardContent>
                        <CardActions>
                            {plan.priceIds?(
                                <Button fullWidth disabled={disabled || selectedPlanId===plan.id?true:false} variant={plan.popular?"contained":"outlined"} onClick={(e) => {
                                    selectPlan(plan);
                                }}>
                                    {plan.free || paymentMethod ?"Subscribe":"Continue"}
                                </Button>
                            ):(
                                <Button fullWidth variant="outlined">Talk to Sales</Button>
                            )}
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}