import { Box, TextField, FormControl, MenuItem } from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import timezones from "../../../inc/timezones.json";

export const SetDestinationBigQuery = forwardRef((props, ref) => {

    const settings = props.settings;
    const mode = props.mode;

    const [formData, setFormData] = useState({
        service_account_key: settings['service_account_key']?settings['service_account_key']:"",
        bigquery_project_id: settings['bigquery_project_id']?settings['bigquery_project_id']:"",
        bigquery_dateset: settings['bigquery_dateset']?settings['bigquery_dateset']:"",
        timezone: settings['timezone']?settings['timezone']:""
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        let tempErrors = {};

        // validate service_account_key has input
        if(formData.service_account_key.trim().length === 0){
            tempErrors.service_account_key = 'Please provide a GCP Service Account Key for connecting to your BigQuery.';
        }

        // validate bigquery_project_id has input
        if(formData.bigquery_project_id.trim().length === 0){
            tempErrors.bigquery_project_id = 'Please provide a BigQuery GCP Project ID for connecting to your BigQuery.';
        }

        // validate bigquery_dateset has input
        if(formData.bigquery_dateset.trim().length === 0){
            tempErrors.bigquery_dateset = 'Please provide a BigQuery Dataset for connecting to your BigQuery.';
        }

        // validate timezone has input
        if(formData.timezone.trim().length === 0){
            tempErrors.timezone = 'Please select a Data Partition Timezone for your BigQuery data partition.';
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleInputChange = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
        if (errors[name]) {
            // Optionally reset errors when the input is changed
            setErrors(prev => ({ ...prev, [name]: undefined }));
        }
    };

    useImperativeHandle(ref, () => ({
        validate,
        getFormData: () => {
            const settings = {
                service_account_key: formData['service_account_key'],
                bigquery_project_id: formData['bigquery_project_id'],
                bigquery_dateset: formData['bigquery_dateset'],
                timezone: formData['timezone']
            }
            if(mode === 'update'){
                // timezone cannot be updated
                settings['timezone'] = undefined
            }
            return settings;
        }
    }));

    return (
        <>
            <Box p={2}>
                <TextField
                    required
                    fullWidth
                    error={errors.service_account_key?true:false}
                    helperText={errors.service_account_key?errors.service_account_key:"Copy and paste your GCP Service Account Key (JSON) here for authentication to BigQuery."} 
                    name="gcp_service_account"
                    label="GCP Service Account Key (JSON)"
                    type="text"
                    value={formData["service_account_key"]}
                    margin="normal"
                    multiline
                    rows={3}
                    onChange={e => handleInputChange("service_account_key", e.target.value.trim())}
                />
            </Box>
            <Box p={2}>
                <TextField
                    required
                    fullWidth
                    error={errors.bigquery_project_id?true:false}
                    helperText={errors.bigquery_project_id?errors.bigquery_project_id:"Specify the GCP project ID of your BigQuery instance."} 
                    name="bigquery_project"
                    label="BigQuery GCP Project ID"
                    type="text"
                    value={formData["bigquery_project_id"]}
                    margin="normal"
                    onChange={e => handleInputChange("bigquery_project_id", e.target.value.trim())}
                />
            </Box>
            <Box p={2}>
                <TextField
                    required
                    fullWidth
                    error={errors.bigquery_dateset?true:false}
                    helperText={errors.bigquery_dateset?errors.bigquery_dateset:"Specify the BigQuery dataset where you want to store the data."}
                    name="bigquery_dataset"
                    label="BigQuery Dataset"
                    type="text"
                    value={formData["bigquery_dateset"]}
                    margin="normal"
                    onChange={e => handleInputChange("bigquery_dateset", e.target.value.trim())}
                />
            </Box>
            <Box p={2}>
                <FormControl fullWidth>
                    <TextField
                        select
                        error={errors.timezone?true:false}
                        helperText={mode==="create"?(errors.timezone?errors.timezone:"Select the timezone for partitioning your data in BigQuery."):""}
                        name="data_partition_timezone"
                        label="Data Partition Timezone"
                        required
                        disabled={mode !== 'create'}
                        value={formData["timezone"]}
                        onChange={e => handleInputChange("timezone", e.target.value)}
                    >
                        {Object.keys(timezones).map(key =>
                            <MenuItem key={key} value={timezones[key]}>{key}</MenuItem>
                        )}
                    </TextField>
                </FormControl>
            </Box>
        </>
    );
});