import { Container, Paper, Box, Typography, Alert, Grid, FormControlLabel, Switch, Stack, Button } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext, FireactContext, SetPageTitle } from "@fireactjs/core";
import { SubscriptionContext } from "@fireactjs/saas";
import { Loader } from "../../Loader";
import { getFirestore, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DeleteDialog } from "../../dialogs/DeleteDialog";
import { ViewSourceApi } from "./ViewSourceApi";
import { ViewSourceAndroid } from "./ViewSourceAndroid";
import { ViewSourceiOS } from "./ViewSourceiOS";
import { ViewSourceJs } from "./ViewSourceJs";
import { clearCache } from "../../../inc/utility";

export const ViewSource = () => {
    const { sourceId } = useParams();
    const title = "View Source";
    const { config } = useContext(FireactContext);
    const { subscription } = useContext(SubscriptionContext);
    const navigate = useNavigate();
    const { firebaseApp } = useContext(AuthContext);
    const db = getFirestore(firebaseApp);
    const [loading, setLoading] = useState(true);
    const [source, setSource] = useState(null);
    const [error, setError] = useState(null);
    const [deleteDialgOpen, setDeleteDialogOpen] = useState(false);


    useEffect(() => {
        setLoading(true);
        if(sourceId && sourceId !== ''){
            getDoc(doc(db, 'sources', sourceId)).then(docSnap => {
                if(docSnap.exists && docSnap.data().subscriptionId === subscription.id){
                    const source = {
                        name: docSnap.data().name,
                        type: docSnap.data().type,
                        enabled: docSnap.data().enabled
                    }
                    setSource(source);
                }else{
                    if(!docSnap.new){
                        throw new Error("No source matches the requested source ID.");
                    }
                }
                setLoading(false);
            }).catch(err => {
                setError(err.message);
                setLoading(false);
            });
        }else{
            setLoading(false);
        }
    },[db, subscription.id, sourceId])
    

    return (
        <Container maxWidth="xl">
            <SetPageTitle title={title} />
            <Paper>
            {loading?(
                <Box p={2}>
                    <Loader />
                </Box>
            ):(
                <>
                    <Box p={2}>
                        <Typography component="h1" variant="h4" align="center">{title}</Typography>
                    </Box>
                    {error !== null?(
                        <Box p={2}>
                            <Alert severity="error">{error}</Alert>
                        </Box>
                    ):(
                        <Box p={2}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography component="h2" variant="h5">{source.name}
                                        <FormControlLabel style={{paddingLeft: "15px"}} control={<Switch defaultChecked={source.enabled} onChange={(e) => {
                                            setDoc(doc(db, 'sources', sourceId), {enabled: e.target.checked}, {merge: true}).then(() => {
                                                // clear cache
                                                setSource(source => ({...source, enabled: e.target.checked}));
                                                clearCache(subscription.server, "source", sourceId);
                                            }).catch(error => {
                                                setError(error.message);
                                                setLoading(false);
                                            })
                                        }}  />} label={source.enabled?"Enabled":"Disabled"} />
                                    </Typography>
                                    <Typography variant="caption"><strong>Source Type: {source.type}</strong></Typography>
                                </Grid>
                                <Grid item>
                                    <Stack direction={"row"} alignContent={"center"} gap={1}>
                                        <Button type="button" color="secondary" variant="outlined" onClick={() => navigate(config.pathnames.Connector.replace(":subscriptionId", subscription.id))}>Connector</Button>
                                        <Button type="button" color="error" variant="outlined" onClick={() => setDeleteDialogOpen(true)}>
                                            <DeleteForeverIcon fontSize="small" style={{paddingRight: "5px"}} />
                                            Delete
                                        </Button>
                                        <Button variant="outlined" onClick={() => navigate(config.pathnames.UpdateSource.replace(":subscriptionId", subscription.id).replace(":sourceId", sourceId))}>
                                            <EditIcon fontSize="small" style={{paddingRight: "5px"}}/>
                                            Update
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                            {source.type ==="web-js" && <ViewSourceJs sourceId={sourceId} server={subscription.server} />}
                            {source.type ==="api" && <ViewSourceApi sourceId={sourceId} server={subscription.server} />}
                            {source.type ==="ios" && <ViewSourceiOS sourceId={sourceId} server={subscription.server} />}
                            {source.type ==="android" && <ViewSourceAndroid sourceId={sourceId} server={subscription.server} />}
                        </Box>
                    )}
                    <DeleteDialog
                        text={`Are you sure you want to delete destination ${source.name}? This action cannot be undone. To confirm, type "${source.name}" in the input field and click the "Delete" button.`}
                        open={deleteDialgOpen}
                        confirmInput={source.name}
                        handleClose={() => setDeleteDialogOpen(false)}
                        handleDelete={() => {
                            setError(null);
                            setDeleteDialogOpen(false);
                            deleteDoc(doc(db, 'sources', sourceId)).then(() => {
                                return clearCache(subscription.server, "subscription", subscription.id);
                            }).then(() => {
                                navigate(config.pathnames.Connector.replace(":subscriptionId", subscription.id));
                            }).catch(error => {
                                setError(error.message);
                            })
                        }}
                    />
                </>
            )}
            </Paper>
        </Container>
    )
}