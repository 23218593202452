import { AuthContext, FireactContext, SetPageTitle } from "@fireactjs/core";
import { SubscriptionContext } from "@fireactjs/saas";
import { Box, Container, Button, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Paper, Radio, RadioGroup, TextField, Typography, Alert } from "@mui/material";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../Loader";
import sourceTypes from "../../../inc/sources.json";
import { getFirestore, doc, getDoc, setDoc, collection, addDoc } from 'firebase/firestore';
import { SetSourceJsSdk } from "./SetSourceJsSdk";
import { SetSourceApi } from "./SetSourceApi";
import { clearCache } from "../../../inc/utility";

export const SetSource = () => {
    const settingsComponentRef = useRef();

    const { sourceId } = useParams();
    const { sourceType } = useParams();
    const title = (sourceId && sourceId !== '')?"Update Source":"Create Source";
    const { config } = useContext(FireactContext);
    const { subscription } = useContext(SubscriptionContext);
    const navigate = useNavigate();
    const { firebaseApp } = useContext(AuthContext);
    const db = getFirestore(firebaseApp);

    const [sourceName, setSourceName] = useState("");
    const [selectedSourceType, setSelectedSourceType] = useState(sourceType);
    const [settings, setSettings] = useState({});
    // const [domains, setDomains] = useState([]);
    // const [dataLayerName, setDataLayerName] = useState("");
    const [enabled, setEnabled] = useState("enabled");

    const [error, setError] = useState(null); // top error message
    const [nameError, setNameError] = useState(null); // name field error message
    const [success, setSuccess] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        if(sourceId && sourceId !== ''){
            getDoc(doc(db, 'sources', sourceId)).then(docSnap => {
                if(docSnap.exists){
                    // initialise the source variables
                    setSourceName(docSnap.data().name);
                    setSelectedSourceType(docSnap.data().type);
                    //setDomains(docSnap.data().domains);
                    setEnabled(docSnap.data().enabled?"enabled":"disabled");
                    // setDataLayerName(docSnap.data().datalayer);
                    setSettings(docSnap.data().settings);
                }else{
                    if(!docSnap.new){
                        throw new Error("No source matches the requested source ID.");
                    }
                }
                setLoading(false);
            }).catch(err => {
                setError(err.message);
                setLoading(false);
            });
        }else{
            setLoading(false);
        }
    },[db, subscription.id, sourceId]);

    const handleSubmit = () => {
        if (settingsComponentRef.current === undefined){
            // no settings component
            return {
                validated: true,
                settings: {}
            }
        }else{
            // Validate form data via the child component
            if (settingsComponentRef.current.validate()) {
                // Get form data from child if it's valid
                setSettings(settingsComponentRef.current.getFormData());
                return {
                    validated: true,
                    settings: settingsComponentRef.current.getFormData()
                }
                // Here you would typically send this data to a server or handle it as needed
            } else {
                return {
                    validated: false
                }
            }
        }
    };
    

    return (
        <Container maxWidth="xl">
            <SetPageTitle title={title} />
            <Paper>
                <Box maxWidth="md" style={{margin: "auto"}}>
                {loading?(
                    <Box p={2}>
                        <Loader />
                    </Box>
                ):(
                    success !== null?(
                        <>
                            <Box p={2}>
                                <Alert severity="success">{success}</Alert>
                            </Box>
                            <Box p={2} alignItems="center" justifyContent="center" display="flex">
                                {(sourceId && sourceId !== '')?(
                                    <Button type="button" color="primary" variant="contained" disabled={processing} onClick={() => {
                                        navigate(config.pathnames.ViewSource.replace(":subscriptionId", subscription.id).replace(":sourceId", sourceId));
                                    }}>Back</Button>
                                ):(
                                    <Button type="button" color="primary" variant="contained" disabled={processing} onClick={() => {
                                        navigate(config.pathnames.Connector.replace(":subscriptionId", subscription.id));
                                    }}>Back</Button>
                                )}                     
                            </Box>
                        </>
                    ):(
                        <>
                            <Box p={2}>
                                <Typography component="h1" variant="h4" align="center">{title}</Typography>
                            </Box>
                            {error !== null &&
                                <Box p={2}>
                                    <Alert severity="error">{error}</Alert>
                                </Box>
                            }
                            <Box p={2}>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        label="Source Type"
                                        value={selectedSourceType}
                                        disabled
                                    >
                                        {Object.keys(sourceTypes).map(key =>
                                            <MenuItem key={key} value={key}>{sourceTypes[key].label}</MenuItem>
                                        )}
                                    </TextField>
                                </FormControl>
                            </Box>
                            {selectedSourceType !== "" &&
                                <>
                                    <Box p={2}>
                                        <TextField required fullWidth label="Source Name" type="text" margin="normal" value={sourceName} error={nameError?true:false} helperText={nameError?nameError:"Input a name for the source to identify it."} onChange={(e) => {
                                            setNameError(null);
                                            setSourceName(e.target.value);
                                        }} />
                                    </Box>
                                    {selectedSourceType === "web-js" &&
                                        <SetSourceJsSdk settings={settings} ref={settingsComponentRef} />
                                    }
                                    {selectedSourceType === "api" &&
                                        <SetSourceApi settings={settings} ref={settingsComponentRef} />
                                    }
                                    <Box p={2}>
                                        <FormControl fullWidth>
                                            <FormLabel>Status</FormLabel>
                                            <RadioGroup name="status" value={enabled} onChange={(e) => setEnabled(e.target.value)}>
                                                <Grid container>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControlLabel value="enabled" control={<Radio />} label="Enabled"/>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControlLabel value="disabled" control={<Radio />} label="Disabled"/>
                                                    </Grid>
                                                </Grid>
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </>
                            }
                            <Box p={2}>
                                <Grid container>
                                    <Grid item xs>
                                        <Button type="button" color="secondary" variant="outlined" disabled={processing} onClick={() => {
                                            navigate(config.pathnames.Connector.replace(":subscriptionId", subscription.id))
                                        }}>Back</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button type="button" variant="contained" disabled={processing} onClick={() => {
                                            setError(null);
                                            setSuccess(null);
                                            setProcessing(true);
                                            // validate and transform inputs
                                            let validationError = false;
                                            if(sourceName.trim().length === 0 || sourceName.trim().length > 50){
                                                validationError = true;
                                                setNameError("Please provide a source name that is 1 to 50 characters in length.");
                                            }
                                            const validation = handleSubmit();
                                            if(validation.validated && !validationError){
                                                if(sourceId && sourceId !== ''){
                                                    setDoc(doc(db, "sources", sourceId), {
                                                        name: sourceName,
                                                        enabled: enabled==="enabled"?true:false,
                                                        settings: validation.settings
                                                    }, {merge: true}).then(res => {
                                                        return clearCache(subscription.server, "source", sourceId);
                                                    }).then(res => {
                                                        setSuccess("The source has been updated successfully.");
                                                        setProcessing(false);
                                                    }).catch(err => {
                                                        setError(err.message);
                                                        setProcessing(false);
                                                    });
                                                }else{
                                                    addDoc(collection(db, "sources"), {
                                                        name: sourceName,
                                                        subscriptionId: subscription.id,
                                                        type: selectedSourceType,
                                                        enabled: enabled==="enabled"?true:false,
                                                        settings: validation.settings
                                                    }).then(res => {
                                                        return clearCache(subscription.server, "subscription", subscription.id);
                                                    }).then(res => {
                                                        setSuccess("The source has been added successfully.");
                                                        setProcessing(false);
                                                    }).catch(err => {
                                                        setError(err.message);
                                                        setProcessing(false);
                                                    });
                                                }
                                            }else{
                                                setError("Please correct your inputs and try again.");
                                                setProcessing(false);
                                                return;
                                            }
                                            

                                        }} >Save</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )
                )}
                </Box>
            </Paper>
        </Container>
    )
}