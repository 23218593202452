import { AuthContext, FireactContext, SetPageTitle } from "@fireactjs/core";
import { SubscriptionContext } from "@fireactjs/saas";
import { Container, Paper, Box, Typography, Alert, RadioGroup, Grid, FormControlLabel, Radio, Divider, TextField, MenuItem, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Button, Stack, Switch } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../Loader";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

export const SetBigQueryDestinationEventMapping = () => {
    const { destinationId, event } = useParams();
    const { subscription } = useContext(SubscriptionContext);
    const title = "Update Event";
    const navigate = useNavigate();
    const { config } = useContext(FireactContext);

    const { firebaseApp } = useContext(AuthContext);
    const db = getFirestore(firebaseApp);
    const [ mapping, setMapping ] = useState([]);
    const [ enabled, setEnabled ] = useState("disabled");
    const [ slots, setSlots] = useState(Array.apply(null, Array(51)).map(() => {return ""}));

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        setLoading(true);
        if(destinationId && destinationId !== ''){
            Promise.all([
                getDoc(doc(db, 'destinations', destinationId)),
                getDoc(doc(db, 'destinations/'+destinationId+'/event_mappings', event))
            ]).then(([destDoc, eventDoc]) => {
                const slots = Array.apply(null, Array(51)).map(() => {return ""});
                // check destination and subscription are aligned
                if(destDoc.data().subscriptionId === subscription.id){
                    const unsorted = eventDoc.data().mapping;
                    const sortedMapping = Object.fromEntries(Object.entries(unsorted).sort());
                    for(const key in sortedMapping){
                        if(sortedMapping[key].slot !== ''){
                            slots[Number(sortedMapping[key].slot)] = key;
                        }
                    }
                    setEnabled(eventDoc.data().enabled?"enabled":"disabled");
                    setMapping(sortedMapping);
                    setSlots(slots);
                }else{
                    setError("No destination matches the requested destination ID.");
                }
                setLoading(false);    
            }).catch(error => {
                setError(error.message);
                setLoading(false);
            });
        }else{
            setError("Invalid event.");
            setLoading(false);
        }
    },[destinationId, db, subscription.id, event]);

    return (
        <Container maxWidth="xl">
            <SetPageTitle title={title} />
            <Paper>
                {loading?(
                    <Box p={2}>
                        <Loader />
                    </Box>
                ):(
                    <>
                        <Box p={2}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography component="h1" variant="h5"><strong>{title}</strong></Typography>
                                </Grid>
                            </Grid>
                            <Grid item>

                            </Grid>
                        </Box>
                        <Divider />
                        {error !== null &&
                            <Box p={2}>
                                <Alert severity="error">{error}</Alert>
                            </Box>
                        }
                        {success !== null &&
                            <Box p={2}>
                                <Alert severity="success">{success}</Alert>
                            </Box>
                        }
                        <Box p={2}>
                        <Grid container justifyContent={"center"} alignItems={"center"} direction={"row"}>
                            <Grid item xs>
                                <Typography variant="body1"><strong>Event:</strong> {event}</Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup name="status" value={enabled} onChange={(e) => setEnabled(e.target.value)}>
                                    <Stack direction={"row"} alignContent={"center"} gap={1}>
                                        <FormControlLabel value="enabled" control={<Radio />} label="Enabled"/>
                                        <FormControlLabel value="disabled" control={<Radio />} label="Disabled"/>
                                    </Stack>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                            
                        </Box>                        
                        {Object.keys(mapping).length > 0 && 
                        <>
                            <Divider />
                            <Box p={2}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                        <Typography component="h6" variant="body1">
                                                            <strong>Variable Name</strong>                                                            
                                                        </Typography>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Typography variant="caption">Show All</Typography>
                                                        <Switch defaultChecked={true} size="small" onClick={e => setShowAll(!e.target.checked)} />
                                                        <Typography variant="caption">Show Enabled Only</Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography component="h6" variant="body1"><strong>Status</strong></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography component="h6" variant="body1"><strong>Type</strong></Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography component="h6" variant="body1"><strong>Slot</strong></Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(mapping).map((key => (
                                                (showAll || mapping[key].enabled) && 
                                                <TableRow hover key={key}>
                                                    <TableCell>
                                                        <Stack direction={"row"} alignContent={"center"} gap={1}>
                                                            {mapping[key].enabled?(
                                                                <CheckCircleIcon color="success" />
                                                            ):(
                                                                <CancelIcon color="error" />
                                                            )}
                                                            <Typography variant="body1">
                                                                {key}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField size="small" select value={mapping[key].enabled || false} label="Status" fullWidth variant="standard">
                                                            <MenuItem style={{fontSize: "smaller"}} value={true} onClick={() => {
                                                                setMapping(mapping => ({...mapping, [key]: {
                                                                    ...mapping[key],
                                                                    enabled: true,
                                                                }}));
                                                            }}>Enabled</MenuItem>
                                                            <MenuItem style={{fontSize: "smaller"}} value={false} onClick={() => {
                                                                setMapping(mapping => ({...mapping, [key]: {
                                                                    ...mapping[key],
                                                                    enabled: false,
                                                                }}));
                                                            }}>Disabled</MenuItem>
                                                        </TextField>                                                        
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField select value={mapping[key].type || "not-set"} label="Type" fullWidth variant="standard" onChange={e => {
                                                            setMapping(mapping => ({...mapping, [key]: {
                                                                ...mapping[key],
                                                                type: e.target.value==="not-set"?"":e.target.value,
                                                            }}));
                                                        }} error={mapping[key].enabled && (mapping[key].type !== 'string' && mapping[key].type !== 'number' && mapping[key].type !== 'currency')}>
                                                            <MenuItem value="not-set" style={{fontSize: "smaller"}}>-- Not Set --</MenuItem>
                                                            <MenuItem value="string" style={{fontSize: "smaller"}}>Text</MenuItem>
                                                            <MenuItem value="number" style={{fontSize: "smaller"}}>Number</MenuItem>
                                                            <MenuItem value="currency" style={{fontSize: "smaller"}}>Currency</MenuItem>
                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField size="small" select label="Slot" value={mapping[key].slot || "not-set"} fullWidth variant="standard" onChange={e => {
                                                            const selectedSlot = e.target.value==="not-set"?"":e.target.value;
                                                            setMapping(mapping => ({...mapping, [key]: {
                                                                ...mapping[key],
                                                                slot: selectedSlot
                                                            }}));
                                                            const workingSlots = slots;
                                                            workingSlots[Number(mapping[key].slot)] = "";
                                                            workingSlots[Number(selectedSlot)] = key;
                                                            setSlots(workingSlots);
                                                        }} error={mapping[key].enabled && (!mapping[key].slot || mapping[key].slot === '')}>
                                                            {slots.map((slot, number) => (
                                                                <MenuItem style={{fontSize: "smaller"}} disabled={slot!=="" && slot!==key} key={number} value={number>0?number.toString():"not-set"}>{number>0?number:"-- Not Set --"}</MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </TableCell>
                                                </TableRow>
                                            )))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </>
                        }
                        <Box p={2}>
                            <Grid container>
                                <Grid item xs>
                                    <Button type="button" color="secondary" variant="outlined" disabled={processing} onClick={() => {
                                        if(destinationId && destinationId !== ''){
                                            navigate(config.pathnames.ViewDestination.replace(":subscriptionId", subscription.id).replace(":destinationId", destinationId));
                                        }else{
                                            navigate(config.pathnames.Connector.replace(":subscriptionId", subscription.id));
                                        }                                            
                                    }}>Back</Button>
                                </Grid>
                                <Grid item>
                                    <Button type="button" variant="contained" disabled={processing} onClick={() => {
                                        setProcessing(true);
                                        // validation
                                        setError(null);
                                        let hasError = false;
                                        for(const event in mapping){
                                            if(mapping[event].enabled){
                                                if(mapping[event].type !== 'string' && mapping[event].type !== 'number' && mapping[event].type !== 'currency'){
                                                    setError(`Please select a valid type for the event "${event}"`);
                                                    hasError = true;
                                                }
                                                if(!mapping[event].slot || mapping[event].slot === ''){
                                                    setError(`Please select a slot for the event "${event}"`);
                                                    hasError = true;
                                                }
                                            }
                                        }
                                        if(hasError){
                                            setProcessing(false);
                                            const main = document.getElementsByTagName('main');
                                            if(main.length > 0){
                                                main[0].scrollTo(0,0);
                                            }
                                        }else{
                                            // saving event settings
                                            setDoc(doc(db, 'destinations/'+destinationId+'/event_mappings', event), {
                                                enabled: enabled==="enabled"?true:false,
                                                mapping: mapping
                                            }).then(() => {
                                                setSuccess('The event has been updated successfully');
                                                setProcessing(false);
                                                const main = document.getElementsByTagName('main');
                                                if(main.length > 0){
                                                    main[0].scrollTo(0,0);
                                                }    
                                            }).catch(error => {
                                                setError(error.message);
                                                setProcessing(false);
                                                const main = document.getElementsByTagName('main');
                                                if(main.length > 0){
                                                    main[0].scrollTo(0,0);
                                                }    
                                            });
                                        }
                                    }}>Save</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                )}
            </Paper>
        </Container>
    );
}