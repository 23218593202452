import { Box, TextField } from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle } from "react";

export const SetSourceJsSdk = forwardRef((props, ref) => {

    const settings = props.settings;

    const [formData, setFormData] = useState({
        domains: settings['domains']?settings['domains'].join("\n"):"",
        data_layer_name: settings['data_layer_name']?settings['data_layer_name']:""
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        let tempErrors = {};
        const domains = formData['domains'].split("\n").map(s => s.trim()).filter(s => s.length > 0);
        if (domains.length===0){
            tempErrors.domains = 'Please input at least one domain (without "https://") in the domain list.';
        }
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleInputChange = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
        if (errors[name]) {
            // Optionally reset errors when the input is changed
            setErrors(prev => ({ ...prev, [name]: undefined }));
        }
    };

    useImperativeHandle(ref, () => ({
        validate,
        getFormData: () => {
            const settings = {
                domains: formData['domains'].split("\n").map(s => s.trim()).filter(s => s.length > 0),
                data_layer_name: formData['data_layer_name']
            }
            return settings;
        }
    }));

    return (
        <>
            <Box p={2}>
                <TextField 
                    required 
                    fullWidth
                    error={errors.domains?true:false}
                    helperText={errors.domains?errors.domains:"List all the top domains of your website journey, including your payment processors (e.g. paypal.com). One line per domain without prefix \"https://\"."} 
                    name="domains" 
                    label="Domain List" 
                    type="text" 
                    value={formData['domains']} 
                    margin="normal" 
                    multiline 
                    rows={5} 
                    onChange={e => handleInputChange("domains", e.target.value)} />
            </Box>
            <Box p={2}>
                <TextField fullWidth name="datalayer" label="Data Layer Object Name" type="text" value={formData['data_layer_name']} margin="normal" onChange={e => handleInputChange("data_layer_name", e.target.value.trim())} />
            </Box>
        </>
    );
});